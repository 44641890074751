import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../appService';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Toast, ToastsManager} from 'ng2-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../environments/environment';
import {ConfirmSaveComponent} from '../confirm-dialog/confirm-save/confirm-save.component';

@Component({
  selector: 'app-super-access',
  templateUrl: './super-access.component.html',
  styleUrls: ['./super-access.component.css']
})
export class SuperAccessComponent implements OnInit {

  selectedSemesterIdToGenerateAllStudentAdvisingTargetList: any = '';

  selectedStudentIdToGenerateSingleStudentAdvisingTarget: any = '';
  selectedSemesterIdToGenerateSingleStudentAdvisingTarget: any = '';

  selectedTeacherIdToGenerateAdvisingTargetListForTeacher: any = '';
  selectedSemesterIdToGenerateAdvisingTargetListForTeacher: any = '';

  semesterListForGenerateAllStudent: any = [];
  semesterListForGenerateSingleStudent: any = [];
  semesterListForGenerateTeacher: any = [];
  generationTypeList: any = ['Beginning', 'Middle', 'Ending'];
  generationType: any = '';

  generateAllStudent: any = {};
  generateSingleStudent: any = {};
  generateTeacher: any = {};

  IsShowGenerateAllStudentNotification = false;
  IsShowGenerateSingleStudentNotification = false;
  IsShowGenerateTeacherNotification = false;

  isLoadingGenAllStdStMdEn = false;

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {

    this.toastr.setRootViewContainerRef(vcr);
  }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterListForGenerateAllStudent();
    this.getSemesterListForGenerateSingleStudent();
    this.getSemesterListForGenerateTeacher();

  }

  getSemesterListForGenerateAllStudent() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/generateReport/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        console.log(res);
        this.semesterListForGenerateAllStudent = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getSemesterListForGenerateSingleStudent() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/generateReport/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        console.log(res);
        this.semesterListForGenerateSingleStudent = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getSemesterListForGenerateTeacher() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/generateReport/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        console.log(res);
        this.semesterListForGenerateTeacher = res;
      },
      msg => {
        console.error('error');
      }
    );
  }


  generateAllStudentAdvisingTargetList() {

    const myParams = new HttpParams().append('semesterId', this.selectedSemesterIdToGenerateAllStudentAdvisingTargetList);
    this.httpClient.get(environment.apiUrl + 'report/generateReport/generateAllStudentAdvisingTargetList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(
        res => {
          console.log('res', res);
          this.generateAllStudent = res;
          this.IsShowGenerateAllStudentNotification = true;
          this.selectedSemesterIdToGenerateAllStudentAdvisingTargetList = '';
        }
      );
  }

  generateAllStudentAdvisingTargetListWithConfirmation(): void {

    // console.log('opening dialog');

    const dialogRef = this.dialog.open(ConfirmSaveComponent, {
      width: 'auto', data: {today: new Date()}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");
        if (result.confirmStatus === 'yes') {
          // console.log('save');
          this.generateAllStudentAdvisingTargetList();
        } else {
          // console.log("not save");
        }
      }
    });
  }

  generateSingleStudentAdvisingTarget() {

    const myParams = new HttpParams().append('studentId', this.selectedStudentIdToGenerateSingleStudentAdvisingTarget)
      .append('semesterId', this.selectedSemesterIdToGenerateSingleStudentAdvisingTarget);
    this.httpClient.get(environment.apiUrl + 'report/generateReport/generateSingleStudentAdvisingTarget', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(
        res => {
          // console.log('res' , res);
          this.generateSingleStudent = res;
          this.IsShowGenerateSingleStudentNotification = true;
          this.selectedStudentIdToGenerateSingleStudentAdvisingTarget = '';
          this.selectedSemesterIdToGenerateSingleStudentAdvisingTarget = '';
        }
      );
  }

  generateSingleStudentAdvisingTargetWithConfirmation(): void {

    // console.log('opening dialog');

    const dialogRef = this.dialog.open(ConfirmSaveComponent, {
      width: 'auto', data: {today: new Date()}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");
        if (result.confirmStatus === 'yes') {
          // console.log('save');
          this.generateSingleStudentAdvisingTarget();
        } else {
          // console.log("not save");
        }
      }
    });
  }

  generateAdvisingTargetListForTeacher() {

    const myParams = new HttpParams().append('teacherId', this.selectedTeacherIdToGenerateAdvisingTargetListForTeacher)
      .append('semesterId', this.selectedSemesterIdToGenerateAdvisingTargetListForTeacher);
    this.httpClient.get(environment.apiUrl + 'report/generateReport/generateAdvisingTargetListForSingleTeacher', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(
        res => {
          // console.log('res' , res);
          this.generateTeacher = res;
          this.IsShowGenerateTeacherNotification = true;
          this.selectedTeacherIdToGenerateAdvisingTargetListForTeacher = '';
          this.selectedSemesterIdToGenerateAdvisingTargetListForTeacher = '';
        }
      );
  }

  generateAdvisingTargetListForTeacherWithConfirmation(): void {

    // console.log('opening dialog');

    const dialogRef = this.dialog.open(ConfirmSaveComponent, {
      width: 'auto', data: {today: new Date()}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");
        if (result.confirmStatus === 'yes') {
          // console.log('save');
          this.generateAdvisingTargetListForTeacher();
        } else {
          // console.log("not save");
        }
      }
    });
  }


  notDivGenerateAllStudent() {
    this.IsShowGenerateAllStudentNotification = false;
  }

  notDivGenerateSingleStudent() {
    this.IsShowGenerateSingleStudentNotification = false;
  }

  notDivGenerateTeacher() {
    this.IsShowGenerateTeacherNotification = false;
  }

  onGenerateTypeBasedSemesterKPI() {
    this.isLoadingGenAllStdStMdEn = true;

    const myParams = new HttpParams()
      .append('semesterId', this.selectedSemesterIdToGenerateAdvisingTargetListForTeacher)
      .append('generationType', this.generationType);
    this.httpClient.get(environment.apiUrl + 'report/generateReport/generateAllStudentKPIBySemester', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(
        res => {
          console.log(res);
          this.isLoadingGenAllStdStMdEn = false;
        }
      );
  }


}
