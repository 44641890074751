import { Component, OnInit } from '@angular/core';
import {ToastsManager} from 'ng2-toastr';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';

@Component({
  selector: 'app-semester-result',
  templateUrl: './semester-result.component.html',
  styleUrls: ['./semester-result.component.css']
})
export class SemesterResultComponent implements OnInit {
  studentId: any;
  isShowData: boolean;

  semesterWiseResultList: any = [];

  constructor(private appService: AppService, private activatedRoute: ActivatedRoute,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.studentId = params['studentId'];
    });
    console.log(this.studentId);
    this.getSemesterWiseResultList();
  }

  getSemesterWiseResultList() {
    this.isShowData = true;
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/student-semester-wise-result-list',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(  res => {
          console.log(res);
          this.semesterWiseResultList = res;
          this.isShowData = false;
        },
        msg => {    console.error('error');    }
      );
  }

}
