import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-teacher-access-by-panel',
  templateUrl: './teacher-access-by-panel.component.html',
  styleUrls: ['./teacher-access-by-panel.component.css']
})
export class TeacherAccessByPanelComponent implements OnInit {
  teacherIdOrNameFilter: any = '';
  mentoringStudentIdOrNameFilter: any = '';
  passoutOrCompletedStudentIdOrNameFilter: any = '';

  headOrAssociateHeadId: any = '';
  designation: any = '';

  teacherListForSelectedHeadOrAssociateHead: any = [];
  selectedTeacherId: any = '';
  selectedTeacherName: any = '';

  studentListForMentoring: any = [];
  studentListForComplete: any = [];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();

    this.route.params.subscribe((params: Params) => {
      this.headOrAssociateHeadId = params['headOrAssociateHeadId'];
      this.designation = params['designation'];
    });

    // console.log(this.headOrAssociateHeadId);
    // console.log(this.designation);

     this.getTeacherListForSelectedHeadOrAssociateHead();


    // this.getStudentListForMentoring();
    // this.getStudentListForComplete();
  }

  getTeacherListForSelectedHeadOrAssociateHead() {
    const myParams = new HttpParams().append('teacherId', this.headOrAssociateHeadId).append('designation', this.designation);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacherListForSelectedHeadOrAssociateHead',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.teacherListForSelectedHeadOrAssociateHead = res;
        },
        msg => {  console.error('error');    }
      );
  }

  getStudentListForTeacher(teacher_id, teacher_first_name) {

    this.selectedTeacherId = teacher_id;
    this.selectedTeacherName = teacher_first_name;

    this.getStudentListForMentoring(teacher_id);
    this.getStudentListForComplete(teacher_id);
  }

  /************ ****************/



  getStudentListForMentoring(teacher_id) {
    const myParams = new HttpParams().append('teacherId', teacher_id);
    this.httpClient.get(environment.apiUrl + 'panelAccess/studentListForMentoring',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForMentoring = res;
        },
        msg => {  console.error('error');    }
      );
  }


  getStudentListForComplete(teacher_id) {
    const myParams = new HttpParams().append('teacherId', teacher_id);
    this.httpClient.get(environment.apiUrl + 'panelAccess/studentListForComplete',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForComplete = res;
        },
        msg => {         console.error('error');    }
      );
  }


  goToHeadAccessSingleStudentMentoringLink(studentId) {
     this.router.navigate(['panel-access/' + this.headOrAssociateHeadId + '/' + this.designation + '/teacher-access/' + studentId + '/' + this.selectedTeacherId + '/single-student-mentoring']);
  }

  protected readonly Date = Date;
}
