import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../../appService';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-expire-students',
  templateUrl: './expire-students.component.html',
  styleUrls: ['./expire-students.component.css']
})
export class ExpireStudentsComponent implements OnInit {

  selectedFacultyForSearch: any = '';
  selectedExpireTimeForSearch: any = '';
  selectedDepartmentForSearch: any = '';
  isShowData: boolean;
  facultyList: any = [];
  expireTimes: any = [
    {year: 'more than 4 or == 5 years' },
    {year: 'more than 5 years' },
  ];
  departmentList: any = [];
  studentData: any = [];
  constructor(private appService: AppService,
              public dialog: MatDialog, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) { }

  ngOnInit() {
    this.getFacultyList();
    // this.getProgramList();
    // this.getDepartmentList();
  }

  getFacultyList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/facultyList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.facultyList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getDepartmentList() {
    const myParams = new HttpParams().append('faculty', this.selectedFacultyForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  // getProgramList() {
  //   const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch);
  //   this.httpClient.get(environment.apiUrl + 'panelAccess/program-list', {
  //     headers: this.appService.getHeadersWithAccessToken(),
  //     params: myParams
  //   }).subscribe(res => {
  //       // // console.log(res);
  //       this.programList = res;
  //     },
  //     msg => {
  //       console.error('error');
  //     }
  //   );
  // }
  getExpireStudentList() {
    const myParams = new HttpParams().append('faculty', this.selectedFacultyForSearch).
    append('department', this.selectedDepartmentForSearch).append('expireDate', this.selectedExpireTimeForSearch);
    this.isShowData = true;
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-report/expire-students',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          this.studentData = res;
          this.isShowData = false;
        },
        msg => {
          this.isShowData = false;
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  isButtonDisable() {
    if (this.selectedFacultyForSearch && this.selectedDepartmentForSearch && this.selectedExpireTimeForSearch) {
      return false;
    }else {
      return true;
    }
  }

  exportToExcel(): void {
    const params = {
      fileName: 'expire_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }

}
