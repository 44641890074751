import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from '../../../../appService';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-meeting-with-guardian-save-dialog',
  templateUrl: './meeting-with-guardian-save-dialog.component.html',
  styleUrls: ['./meeting-with-guardian-save-dialog.component.css']
})
export class MeetingWithGuardianSaveDialogComponent implements OnInit {


  constructor(private appService: AppService,
              public dialogRef: MatDialogRef<MeetingWithGuardianSaveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();

    // console.log(" in dialog ctrl init");
    // console.log(this.data);
  }

  yes(): void {
    this.dialogRef.close({confirmStatus: 'yes'});
  }

  no(): void {
    this.dialogRef.close({confirmStatus: 'no'});
  }

}
