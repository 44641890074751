import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../appService';
import {environment} from '../../environments/environment';
import {Toast, ToastsManager} from 'ng2-toastr';

@Component({
  selector: 'app-assign-dean-monitor-head',
  templateUrl: './assign-dean-monitor-head.component.html',
  styleUrls: ['./assign-dean-monitor-head.component.css']
})
export class AssignDeanMonitorHeadComponent implements OnInit {
  deanIdOrNameFilter: any = '';
  teacherIdOrNameFilter: any = '';
  alreadyAssignedHeadIdOrNameFilter: any = '';

  employeeIdorNameToSearchDean = '';
  employeeIdorNameToSearchHead = '';
  deanList: any = [];
  HeadList: any = [];
  selectedDean: any= {};
  selectedHeadList = [];

  alreadyAssignedDeanHeadList: any = [];
  deanIdAndNameToSearchAlreadyAssignedDeanHeadList= '';
  HeadIdAndNameToSearchAlreadyAssignedDeanHeadList = '';

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {    this.appService.hasAccessToken();   }

  getDeanListForHead() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchDean);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorHead/employeeList',
      {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.deanList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getHeadList() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchHead);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorHead/employeeList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.HeadList = res;
        },
        msg => {         console.error('error');    }
      );
  }

  selectDean(d) {
    this.selectedDean = d;
  }

  selectHead(ah) {
    this.selectedHeadList.push(ah);
  }

  clearHeadList() {
    this.selectedHeadList = [];

  }
  clearSelectedDean() {
    this.selectedDean = {};
  }
  clearSingleSelectedHead(selected, index) {
    this.selectedHeadList.splice(index, 1);
  }


  assignDeanToMonitorHead() {
    const tempArr = [];
    for (const sah of this.selectedHeadList)  {  tempArr.push(sah.EMPLOYEE_ID);   }
    this.httpClient.post(environment.apiUrl + 'assignDeanMonitorHead/assignDean',
      {deanId: this.selectedDean.EMPLOYEE_ID, headIdList: tempArr},
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(  res => {
          // console.log(res);

          if (res['status'] === 'success') {
            this.toastr.success('Successfully Assigned!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {this.selectedDean = {}, this.selectedHeadList = []; setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error('Assigned Failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }


  alreadyAssignedDeanHeadListSearchByDean() {
    const myParams = new HttpParams().append('deanIdorName', this.deanIdAndNameToSearchAlreadyAssignedDeanHeadList);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorHead/deanHeadListByDean',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedDeanHeadList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  alreadyAssignedDeanHeadListSearchByHead() {
    const myParams = new HttpParams().append('HeadIdorName', this.HeadIdAndNameToSearchAlreadyAssignedDeanHeadList);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorHead/deanHeadListByHead',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedDeanHeadList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  deleteAssignedHeadFromTeacher(id) {
    const myParams = new HttpParams().append('id', id);
    this.httpClient.delete(environment.apiUrl + 'assignDeanMonitorHead/deleteAssignedDeanToHead',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success('Successfully Deleted!', 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => { this.alreadyAssignedDeanHeadListSearchByDean(); setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          } else {
            this.toastr.success('Delete Failed!', 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);   });
          }
        },
        msg => {    console.error('error');    }
      );
  }

}
