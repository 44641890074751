import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';

import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-meeting-with-course-teacher-by-head',
  templateUrl: './meeting-with-course-teacher-by-head.component.html',
  styleUrls: ['./meeting-with-course-teacher-by-head.component.css']
})
export class MeetingWithCourseTeacherByHeadComponent implements OnInit {

  selectedSemester: any = '';

  pendingMeetingListFilter: any = '';
  pendingMeetingList: any = [];
  completeMeetingListFilter: any = '';
  completeMeetingList: any = [];

  teacherId: any = '';
  studentId: any = '';
  semesterList: any = [];
  cdnLocMentoring= environment.cdnLocMentoring;



  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,  public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();

    this.route.params.subscribe( (params: Params) => {
      this.studentId = params['studentId'];
      this.teacherId = params['teacherId'];
    });

    // console.log(this.studentId);
    // console.log(this.teacherId);

    this.getSemesterList();
  }


 getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'headAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }




  getPendingMeetingList() {
    const myParams = new HttpParams()
      .append('studentId', this.studentId)
      .append('semesterId', this.selectedSemester)
      .append('teacherId', this.teacherId);

    this.httpClient.get(environment.apiUrl + 'headAccess/teacherMeetingCourseTeacher/pendingMeetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams})
      .subscribe(res => {
        //  console.log(res);
          this.pendingMeetingList = res;
        },
        msg => {
          console.error('error');
        }
      );

  }


  getCompleteMeetingList() {
    const myParams = new HttpParams()
      .append('studentId', this.studentId)
      .append('semesterId', this.selectedSemester)
      .append('teacherId', this.teacherId);

    this.httpClient.get(environment.apiUrl + 'headAccess/teacherMeetingCourseTeacher/completeMeetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
         //  console.log(res);
          this.completeMeetingList = res;
     }, msg => {
          console.error('error');
        }
      );
  }


}
