import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Toast, ToastsManager} from 'ng2-toastr';
import {AppService} from '../../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SingleStudentMentoringByDeanComponent} from './single-student-mentoring-by-dean/single-student-mentoring-by-dean.component';

@Component({
  selector: 'app-teacher-access-by-dean',
  templateUrl: './teacher-access-by-dean.component.html',
  styleUrls: ['./teacher-access-by-dean.component.css']
})
export class TeacherAccessByDeanComponent implements OnInit {
  mentoringStudentIdOrNameFilter: any = '';
  passoutOrCompletedStudentIdOrNameFilter: any = '';


  teacherId: any = '';

  studentListForMentoring: any = [];
  studentListForComplete: any = [];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe((params: Params) => {
      this.teacherId = params['teacherId'];
    });

    // console.log(this.teacherId);

    this.getStudentListForMentoring();
    this.getStudentListForComplete();
  }


  getStudentListForMentoring() {
    const myParams = new HttpParams().append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'deanAccess/studentListForMentoring',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForMentoring = res;
        },
        msg => {  console.error('error');    }
      );
  }


  getStudentListForComplete() {
    const myParams = new HttpParams().append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'deanAccess/studentListForComplete',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForComplete = res;
        },
        msg => {         console.error('error');    }
      );
  }

  goToSingleStudentMentoringLink(studentId) {
    this.router.navigate(['dean-access/' + studentId + '/' + this.teacherId + '/teacher-access/single-student-mentoring']);
  }


}
