import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-course-attendance-dialog',
  templateUrl: './course-attendance-dialog.component.html',
  styleUrls: ['./course-attendance-dialog.component.css']
})
export class CourseAttendanceDialogComponent implements OnInit {

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<CourseAttendanceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
