import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../../../environments/environment';
import {AppService} from '../../../appService';
import {Toast, ToastsManager} from 'ng2-toastr';

@Component({
  selector: 'app-meeting-with-student-by-associatehead',
  templateUrl: './meeting-with-student-by-associatehead.component.html',
  styleUrls: ['./meeting-with-student-by-associatehead.component.css']
})
export class MeetingWithStudentByAssociateheadComponent implements OnInit {
  reasonForMeetingOrSemesterFilter: any = '';

  teacherId: any = '';
  studentId: any = '';
  semesterList: any = [];
  selectedSemesterIdToSearchMeeting = '';
  meetingList: any = [];


  date = new Date();
  cdnLocMentoring= environment.cdnLocMentoring;
  @ViewChild('fileInput') fileInput;


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }



  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe( (params: Params) => {
      this.studentId = params['studentId'];
      this.teacherId = params['teacherId'];
    });
    // console.log(this.studentId);
    // console.log(this.teacherId);

    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'associateHeadAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {   // console.log(res);
        this.semesterList = res;
      }, msg => {
        console.error('error');
      }
    );
  }


  getMeetingList() {
    const myParams = new HttpParams().append('studentId', this.studentId)
      .append('semesterId', this.selectedSemesterIdToSearchMeeting)
      .append('teacherId', this.teacherId);

    this.httpClient.get(environment.apiUrl + 'associateHeadAccess/teacherMeetingStudent/meetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
          // console.log(res);
          this.meetingList = res;
        },
        msg => {
          console.error('error');
        }
      );

  }



}
