import { Component, OnInit } from '@angular/core';
import {Toast, ToastsManager} from 'ng2-toastr';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-advising-target-setting-and-kpi-by-teacher',
  templateUrl: './advising-target-setting-and-kpi-by-teacher.component.html',
  styleUrls: ['./advising-target-setting-and-kpi-by-teacher.component.css']
})
export class AdvisingTargetSettingAndKpiByTeacherComponent implements OnInit {

  isSearchResultShow: boolean = false;
  selectedOption;
  browserDetectionMessage: string = '';
  mentorDetails: any = {};
  selectedSemesterForSearch: any = null;
  semesterList: any = [];
  advisingTargetList: any = [];
  optionsChooseList: any = ['Beginning', 'Ending'];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService, public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterList();
    this.getMentorDetails();

    var isChrome = !!window['chrome']&& !!window['chrome']['webstore'];
    // console.log(isChrome);
    if(!isChrome){
      this.browserDetectionMessage = " Note: You are not using Google Chrome browser.Use Google Chrome To print the document.";
    }

  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/mentorAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      }, msg => {
        console.error('error');
      }
    );
  }

  getMentorDetails() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/mentorAccess/mentorDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.mentorDetails = res;
      }, msg => {
        console.error('error');
      }
    );
  }

  getAdvisingTargetList() {
    // this.isSearchResultShow = true;
    const myParams = new HttpParams()
      .append('semesterId', this.selectedSemesterForSearch.SEMESTER_ID)
      .append('generationType', this.selectedOption);
    this.httpClient.get(environment.apiUrl + 'report/mentorAccess/advisingTargetList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
         // console.log(res);
          this.isSearchResultShow = false;
         const valTemp: any = res;
         if (valTemp.length > 0) {
           this.advisingTargetList = res;
         } else {
             this.toastr.error('No data found!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
               .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 3000);         });
         }

        },
        msg => {
          console.error('error');
        }
      );
  }


}
