import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {Toast, ToastsManager} from 'ng2-toastr';
import {AppService} from '../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-assign-teacher-monitor-assigned-student',
  templateUrl: './assign-teacher-monitor-assigned-student.component.html',
  styleUrls: ['./assign-teacher-monitor-assigned-student.component.css']
})
export class AssignTeacherMonitorAssignedStudentComponent implements OnInit {
  teacherIdOrNameFilter: any = '';
  studentIdOrNameFilter: any = '';

  employeeIdorNameToSearchTeacher = '';
  teacherIdToSearchStudent = '';
  oldTeacherId = '';

  selectedStudentIdToSearchMentor = '';
  mentorListForSelectedStudent: any = [];

  newTeacherListToSelect: any = [];
  studentListToReassignStudent: any = [];
  selectedTeacher: any = {};
  selectedStudentList: any = [];

  teacherIdAndNameToSearchAlreadyAssignedTeacherStudentList= '';
  studentIdAndNameToSearchAlreadyAssignedTeacherStudentList= '';
  alreadyAssignedTeacherStudentList: any= [];
  assignStudentListForSelectedTeacher: any= [];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }
  ngOnInit() {
    this.appService.hasAccessToken();
  }


  getTeacherListToSelectTeacher() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchTeacher);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorAssignedStudent/employeeList',  {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.newTeacherListToSelect = res;
        },
        msg => {    console.error('error');    }
      );
  }


  getStudentListToReassignStudent() {
    this.oldTeacherId = this.teacherIdToSearchStudent;
    const myParams = new HttpParams().append('teacherId', this.teacherIdToSearchStudent);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorAssignedStudent/studentListToReassignByTeacherId',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListToReassignStudent = res;
        },
        msg => {         console.error('error');    }
      );
  }


  selectTeacher(t) {
    this.selectedTeacher = t;
    setTimeout(() => {
      if (Object.keys(this.selectedTeacher).length === 0) {
        // Object is empty
        // console.log('empty');
      } else {
        // Object is NOT empty
        // console.log(this.selectedTeacher['EMPLOYEE_ID']);
        // console.log('not empty');
        this.getStudentListForTeacher(this.selectedTeacher['EMPLOYEE_ID']);
      }
    }, 200);

  }


   getStudentListForTeacher(employeeId) {
    const myParams = new HttpParams().append('employeeId', employeeId);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorAssignedStudent/alreadyAssignedStudentList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.assignStudentListForSelectedTeacher = res;
        },
        msg => {         console.error('error');    }
      );
  }




  reAssignStudentToNewTeacher(student) {
    // console.log(student);

    this.httpClient.put(environment.apiUrl + 'assignTeacherMonitorAssignedStudent/reAssignStudentToNewTeacher',
      {
        id: student.id,
        newTeacherId: this.selectedTeacher.EMPLOYEE_ID,
        oldTeacherId: this.oldTeacherId,
        studentId: student.STUDENT_ID
      },
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(  res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);
                this.getStudentListForTeacher(this.selectedTeacher['EMPLOYEE_ID']);
                this.getStudentListToReassignStudent();
              });
          } else {
            this.toastr.error(res['message'], 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          }
        },
        msg => {    console.error('error');    }
      );
  }


  getMentorListForSelectedStudent() {
    const myParams = new HttpParams().append('studentId', this.selectedStudentIdToSearchMentor);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorAssignedStudent/mentorListForSelectedStudent',
      {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.mentorListForSelectedStudent = res;
        },
        msg => {    console.error('error');    }
      );
  }

}
