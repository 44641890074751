import { Component, OnInit } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Toast, ToastsManager} from 'ng2-toastr';
import {AppService} from '../../appService';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-advising-target-setting-and-kpi-by-dean',
  templateUrl: './advising-target-setting-and-kpi-by-dean.component.html',
  styleUrls: ['./advising-target-setting-and-kpi-by-dean.component.css']
})
export class AdvisingTargetSettingAndKpiByDeanComponent implements OnInit {

  isSearchResultShow = false;
  selectedOption;
  browserDetectionMessage = '';
  deanDetails: any = {};
  selectedSemesterForSearch: any = null;
  semesterList: any = [];
  advisingTargetListHead: any = [];
  advisingTargetListAssociateHead: any = [];
  optionsChooseList: any = ['Beginning', 'Ending'];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService, public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterList();
    this.getDeanDetails();

    const isChrome = !!window['chrome'] && !!window['chrome']['webstore'];
    // console.log(isChrome);
    if (!isChrome) {
      this.browserDetectionMessage = ' Note: You are not using Google Chrome browser.Use Google Chrome To print the document.';
    }



  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/deanAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      }, msg => {
        console.error('error');
      }
    );
  }

  getDeanDetails() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/deanAccess/deanDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.deanDetails = res;
      }, msg => {
        console.error('error');
      }
    );
  }

  getAdvisingTargetListHead() {
    this.isSearchResultShow = true;
    const myParams = new HttpParams()
      .append('semesterId', this.selectedSemesterForSearch.SEMESTER_ID)
      .append('generationType', this.selectedOption);
    this.httpClient.get(environment.apiUrl + 'report/deanAccess/advisingTargetListHead', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.isSearchResultShow = false;
          const valTemp: any = res;
          if (valTemp.length > 0) {
            this.advisingTargetListHead = res;
          } else {
            this.toastr.error('No data found!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 3000);         });
          }
        },
        msg => {
          console.error('error');
        }
      );
  }

  getAdvisingTargetListAssociateHead() {
    this.isSearchResultShow = true;
    const myParams = new HttpParams()
      .append('semesterId', this.selectedSemesterForSearch.SEMESTER_ID)
      .append('generationType', this.selectedOption);
    this.httpClient.get(environment.apiUrl + 'report/deanAccess/advisingTargetListAssociateHead', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.isSearchResultShow = false;
          const valTemp: any = res;
          if (valTemp.length > 0) {
            this.advisingTargetListAssociateHead = res;
          } else {
            this.toastr.error('No data found!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 3000);         });
          }
        },
        msg => {
          console.error('error');
        }
      );
  }

}
