import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {AppService} from '../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TeacherAccessByDeanComponent} from './teacher-access-by-dean/teacher-access-by-dean.component';

@Component({
  selector: 'app-dean-access',
  templateUrl: './dean-access.component.html',
  styleUrls: ['./dean-access.component.css']
})
export class DeanAccessComponent implements OnInit {
  headIdOrNameFilter: any = '';
  teacherIdOrNameFilter: any = '';
  associateHeadIdOrNameFilter: any = '';

  associateHeadEmployeeList: any = [] ;
  headEmployeeList: any = [] ;
  selectedTeacherId: any = '';
  selectedTeacherName: any = '';
  selectedDesignation: any = '' ;

  teacherListForSelectedEmployee: any = [];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.getAssociateHeadEmployeeListForDean();
    this.getHeadEmployeeListForDean();
  }

  getAssociateHeadEmployeeListForDean() {
    this.httpClient.get(environment.apiUrl + 'deanAccess/associateHeadEmployeeList',  {headers: this.appService.getHeadersWithAccessToken() })
      .subscribe(  res => {
           // console.log(res);
          this.associateHeadEmployeeList = res;
        },
        msg => {    console.error('error');    }
      );
  }


  getHeadEmployeeListForDean() {
    this.httpClient.get(environment.apiUrl + 'deanAccess/headEmployeeList',  {headers: this.appService.getHeadersWithAccessToken() })
      .subscribe(  res => {
           // console.log(res);
          this.headEmployeeList = res;
        },
        msg => {    console.error('error');    }
      );
  }




  getTeacherList(teacher_id, teacher_first_name, designation) {

    this.selectedTeacherId = teacher_id;
    this.selectedTeacherName = teacher_first_name;
    this.selectedDesignation = designation;

    this.getTeacherListForSelectedEmployee(teacher_id, designation);
  }

  getTeacherListForSelectedEmployee(teacher_id, designation) {
    const myParams = new HttpParams().append('teacherId', teacher_id).append('designation', designation);
    this.httpClient.get(environment.apiUrl + 'deanAccess/teacherListForSelectedEmployee',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.teacherListForSelectedEmployee = res;
        },
        msg => {  console.error('error');    }
      );
  }

  goToAccessSingleStudentMentoringLink(teacherId, teacher_first_name) {

    this.router.navigate(['dean-access/' + teacherId + '/teacher-access']);
  }


}
