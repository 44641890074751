import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-meeting-with-guardian-by-panel',
  templateUrl: './meeting-with-guardian-by-panel.component.html',
  styleUrls: ['./meeting-with-guardian-by-panel.component.css']
})
export class MeetingWithGuardianByPanelComponent implements OnInit {
  meetingWithGuardianReasonForMeetingOrSemesterFilter: any = '';

  headOrAssociateHeadId: any = '';
  designation: any = '';
  teacherId: any = '';
  studentId: any = '';


  semesterList: any = [];
  selectedSemesterIdToSearchMeeting = '';
  meetingList: any = [];
  // date filter
  date = new Date();
  cdnLocMentoring= environment.cdnLocMentoring;

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();

    this.route.params.subscribe((params: Params) => {
      this.headOrAssociateHeadId = params['headOrAssociateHeadId'];
      this.designation = params['designation'];
      this.studentId = params['studentId'];
      this.teacherId = params['teacherId'];
    });

    // console.log(this.headOrAssociateHeadId);
    // console.log(this.designation);
    // console.log(this.studentId);
    // console.log(this.teacherId);


    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  getMeetingList() {
    const myParams = new HttpParams().append('studentId', this.studentId).append('teacherId', this.teacherId).append('semesterId', this.selectedSemesterIdToSearchMeeting);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacherMeetingGuardian/meetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.meetingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

}
