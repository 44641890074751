import { Component, OnInit } from '@angular/core';
import {AppService} from '../appService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent {

  constructor(private appService: AppService, private router: Router) {

  }

  goToLogin() {
    this.appService.deleteAccessToken();
    this.router.navigate(['/login']);
  }
}
