import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AscrfctDialogComponent} from '../../teacher-access/all-student-counselling-request-from-course-teacher/ascrfct-dialog/ascrfct-dialog.component';
import * as XLSX from 'xlsx';
import {AppService} from '../../appService';
import {MatDialog} from '@angular/material/dialog';
import {ToastsManager} from 'ng2-toastr';

@Component({
  selector: 'app-student-not-registred',
  templateUrl: './student-not-registred.component.html',
  styleUrls: ['./student-not-registred.component.css']
})
export class StudentNotRegistredComponent implements OnInit {
  selectedMentorForSearch: any = '';
  selectedSemesterForSearch: any = '';
  selectedDepartmentForSearch: any = '';
  isShowData: boolean;
  mentorList: any = [];
  semesterList: any = [];
  departmentList: any = [];
  studentData: any = [];
  constructor(private appService: AppService,
              public dialog: MatDialog,
              private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr); }

  ngOnInit() {
    this.getSemesterList();
    this.getDepartmentList();
    // this.getMentorList();
  }

  getMentorList() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.mentorList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }
  getDepartmentList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/v1/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {  console.error('error');   }
      );
  }

  getMentorWiseUnregisteredStudentList() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch).
    append('semester', this.selectedSemesterForSearch).append('teacher', this.selectedMentorForSearch);
    this.isShowData = true;
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-report/mentor-wise-unregistered-student-list',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          this.studentData = res;
          this.isShowData = false;
        },
        msg => {
          this.isShowData = false;
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }
  isButtonDisable() {
    if (this.selectedMentorForSearch && this.selectedSemesterForSearch && this.selectedDepartmentForSearch) {
      return false;
    } if (this.selectedMentorForSearch === 0 && this.selectedSemesterForSearch && this.selectedDepartmentForSearch) {
      return false;
    } else {
      return true;
    }
  }


  // counsellingStudent(pendingMeeting): void {
  //   const dialogRef = this.dialog.open(AscrfctDialogComponent, {
  //     width: 'auto',
  //     disableClose: true,
  //     data: {pendingMeeting: pendingMeeting}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     // console.log('The dialog was closed');
  //     // this.getPendingMeetingList();
  //     this.getCompleteMeetingList();
  //   });
  // }
  exportToExcel(): void {
    const params = {
      fileName: 'unregistered_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }

}
