import { Component, OnInit } from '@angular/core';
import {AppService} from '../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-proctor-access',
  templateUrl: './proctor-access.component.html',
  styleUrls: ['./proctor-access.component.css']
})
export class ProctorAccessComponent implements OnInit {
  activeTabNumber= 1;
  constructor(private appService: AppService, private httpClient: HttpClient) { }

  ngOnInit() {
  }
  activeTab(tabNo) {
    this.activeTabNumber = tabNo;
  }
  hasRole(role) {
    // console.log('has role ', this.appService.hasAccess(role));
    return this.appService.hasAccess(role);
  }

}
