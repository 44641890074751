
import {Component, OnInit, Injectable, ViewContainerRef} from '@angular/core';
import {Headers, Http, Response} from '@angular/http';
import 'rxjs/Rx';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Params, Router} from '@angular/router';

import {Toast, ToastsManager} from 'ng2-toastr/ng2-toastr';

import { environment } from '../../environments/environment';
import {AppService} from '../appService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  username = '';
  password = '';
  constructor(private appService: AppService,  private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,  private router: Router,  private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
    // console.log('LoginComponent ngOnInit');
  }


  login() {
    // console.log(environment.apiUrl);
    const data = {username: this.username, password: this.password};
    this.httpClient.post(environment.apiUrl + 'login', data, {headers: this.appService.getHeadersWithoutAccessToken()})
      .subscribe(
        res => {
          /*// console.log(res['message']);
          // console.log(res['accessToken']);
          // console.log(res['commaSeparatedRoles']);
          // console.log(res['deviceName']);
          */
          if (res['message'] === 'success') {
            this.toastr.success('Login Success!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
            this.appService.saveAccessToken(res);
            this.router.navigate(['/dashboard'], { queryParams: { employeeId: res['employeeId'] } });
          }
          if (res['message'] === 'failed') {
            this.toastr.error('Login failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Login Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }

  forgotPassward() {

  }

}
