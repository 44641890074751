import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import printJS from 'print-js';

@Component({
  selector: 'app-mentoring-rpt',
  templateUrl: './mentoring-rpt.component.html',
  styleUrls: ['./mentoring-rpt.component.css']
})
export class MentoringRptComponent implements OnInit {
  mentoringRpt: any = null;
  isShowData = false;

  constructor(private appService: AppService, private httpClient: HttpClient, public dialog: MatDialog,
              public dialogRef: MatDialogRef<MentoringRptComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.getDataForReport();
  }

  getDataForReport() {
    this.isShowData = true;
    const myParams = new HttpParams()
      .append('teacherId', this.data.teacherId)
      .append('semesterId', this.data.semesterId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-lookup/mentor-report',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.mentoringRpt = res;
          this.isShowData = false;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }

  onPrintReport(): void {
    console.log('print');
    printJS({
      printable: 'mentoringRpt',
      type: 'html',
      targetStyles: ['*']
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
