import { Component, OnInit } from '@angular/core';
import {AppService} from '../appService';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../environments/environment';
import printJS from 'print-js';
import {MatDialog} from '@angular/material/dialog';
import {MentoringRptComponent} from './mentoring-rpt/mentoring-rpt.component';

@Component({
  selector: 'app-teacher-lookup',
  templateUrl: './teacher-lookup.component.html',
  styleUrls: ['./teacher-lookup.component.css']
})
export class TeacherLookupComponent implements OnInit {
  teacherId: any;
  selectedSemester: any;
  isShowData: boolean;
  isShowPrintRpt: boolean;

  semesterList: any = [];
  mentoringList: any = {};
  semesterData: any = null;

  teacherInfo: any = {};
  mentorStudentList: any = [];
  completedMeetingList: any = [];
  pendingMeetingList: any = [];
  counsellingRequestSentToMentor: any = [];
  counsellingRequestReceivedAsMentor: any = [];
  mentorMeetingListWithStudent: any = [];
  mentorTeacherStudentCounselling: any = [];
  mentorMeetingWithGuardian: any = [];
  mentorMeetingWithCourseTeacher: any = [];
  mentorSemesterCourseList: any = [];
  mentoringRpt: any = null;

  mentorSemesterCourseListColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Course Code', field: 'courseCode', filter: 'agTextColumnFilter'},
    {headerName: 'Course Title', field: 'courseTitle', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Total Credit', field: 'totalCredit'},
    {headerName: 'Section Name', field: 'sectionName'},
    {headerName: 'No. Of Student', field: 'numberOfStudent'},
  ];

  mentorStudentListColumn = [
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 220, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'DUE', field: 'dueAmount', width: 80},
    {headerName: 'DO Zone', field: 'dropOutZone', width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Email', field: 'EMAIL', width: 220, },
    {headerName: 'Mobile', field: 'MOBILE', width: 120, },
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120, },
    {headerName: 'Mother Mobile', field: 'motherMobile', width: 120, },
  ];

  mentorMeetingListWithStudentColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student Id', field: 'student_id', filter: 'agTextColumnFilter'},
    {headerName: 'Student Name', field: 'STUDENT_NAME', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Date', field: 'createdDate'},
    {headerName: 'Meeting Topic', field: 'meeting_topic', filter: 'agTextColumnFilter', resizable: true },
    {headerName: 'Meeting Instruction', field: 'meeting_instruction', resizable: true },
    {headerName: 'Meeting Remarks', field: 'meeting_remarks', resizable: true},
    {headerName: 'CGPA', field: 'GPA', sortable: true, unSortIcon: true, resizable: true, width: 100},
    {headerName: 'Course Taken', field: 'CourseTaken', sortable: true, unSortIcon: true, resizable: true, width: 120},
    {headerName: 'Completed Credit', field: 'COMPLETED_CREDIT', sortable: true, unSortIcon: true, resizable: true, width: 130},
    {headerName: 'Proctorial Issue', field: '', sortable: true, unSortIcon: true, resizable: true, width: 130}
  ];
  mentorTeacherStudentCounsellingColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student Id', field: 'student_id', filter: 'agTextColumnFilter'},
    {headerName: 'Student Name', field: 'STUDENT_NAME', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Date', field: 'createdDate'},
    {headerName: 'Meeting Reason', field: 'meeting_reason', filter: 'agTextColumnFilter', resizable: true },
    {headerName: 'Counselling Title', field: 'counselling_title', resizable: true },
    {headerName: 'Counselling Details', field: 'counselling_detail', resizable: true},
    {headerName: 'Course Taken', field: 'CourseTaken', sortable: true, unSortIcon: true, resizable: true, width: 120},
    {headerName: 'Completed Credit', field: 'COMPLETED_CREDIT', sortable: true, unSortIcon: true, resizable: true, width: 130},
    {headerName: 'CGPA', field: 'CGPA_ON_COMPLETED_CREDIT', sortable: true, unSortIcon: true, resizable: true, width: 130}
  ];

  mentorMeetingListWithGuardianColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student Id', field: 'STUDENT_ID', filter: 'agTextColumnFilter'},
    {headerName: 'Student Name', field: 'STUDENT_NAME', filter: 'agTextColumnFilter'},
    {headerName: 'Guardian Type', field: 'guardian_type'},
    {headerName: 'Meeting Date', field: 'meetingDate'},
    {headerName: 'Meeting Topic', field: 'meeting_topic', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Meeting Instruction', field: 'meeting_instruction', resizable: true},
    {headerName: 'Meeting Remarks', field: 'meeting_remarks', resizable: true},
    {headerName: 'Course Taken', field: 'CourseTaken', sortable: true, unSortIcon: true, resizable: true, width: 120},
    {headerName: 'Completed Credit', field: 'COMPLETED_CREDIT', sortable: true, unSortIcon: true, resizable: true, width: 130},
    {headerName: 'Proctorial Issue', field: '', sortable: true, unSortIcon: true, resizable: true, width: 130}
  ];
  mentorMeetingWithCourseTeacherColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Teacher Id', field: 'request_to_teacher_id', filter: 'agTextColumnFilter'},
    {headerName: 'Course Name', field: 'course_title', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Date', field: 'requestDate'},
    {headerName: 'Counselling Title', field: 'counselling_title', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Counselling Details', field: 'counselling_detail', resizable: true},
    {headerName: 'Meeting Remarks', field: 'counselling_teacher_remarks', resizable: true},
  ];

  completedMeetingListColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student Id', field: 'student_id', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Reason', field: 'meeting_reason', filter: 'agTextColumnFilter'},
    {headerName: 'Request Date', field: 'requestDate'},
    {headerName: 'Request Title', field: 'counselling_request_title', resizable: true},
    {headerName: 'Request Details', field: 'counselling_request_detail', filter: 'agTextColumnFilter', resizable: true},
  ];

  pendingMeetingListColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student Id', field: 'student_id', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Reason', field: 'meeting_reason', filter: 'agTextColumnFilter'},
    {headerName: 'Request Date', field: 'requestDate'},
    {headerName: 'Request Title', field: 'counselling_request_title', resizable: true},
    {headerName: 'Request Details', field: 'counselling_request_detail', filter: 'agTextColumnFilter', resizable: true},
  ];

  counsellingRequestSentToMentorColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student ID', field: 'student_id', filter: 'agTextColumnFilter'},
    {headerName: 'Student Name', field: 'STUDENT_NAME', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Reason', field: 'meeting_reason', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Request Date', field: 'requestDate'},
    {headerName: 'Request Title', field: 'counselling_request_title', resizable: true},
    {headerName: 'Teacher ID', field: 'mentor_teacher_id', resizable: true},
    {headerName: 'Request Details', field: 'counselling_request_detail', filter: 'agTextColumnFilter', resizable: true},
  ];

  counsellingRequestReceivedAsMentorColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Student ID', field: 'student_id', filter: 'agTextColumnFilter'},
    {headerName: 'Student Name', field: 'STUDENT_NAME', filter: 'agTextColumnFilter'},
    {headerName: 'Meeting Reason', field: 'meeting_reason', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Request Date', field: 'requestDate'},
    {headerName: 'Request Title', field: 'counselling_request_title', resizable: true},
    {headerName: 'Teacher ID', field: 'request_from_teacher_id', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Request Details', field: 'counselling_request_detail', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Solution', field: 'mentor_remarks', filter: 'agTextColumnFilter', resizable: true},
  ];

  constructor(private appService: AppService, private _sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, public dialog: MatDialog) { }

  ngOnInit() {
    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  getTeacherLookupSemesterData() {
    this.isShowData = true;
    const myParams = new HttpParams()
      .append('teacherId', this.teacherId)
      .append('semesterId', this.selectedSemester);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-lookup/semester-data',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.semesterData = res;
          this.teacherInfo = res['teacherInfo'];
          this.mentorStudentList = res['mentorStudentList'];
          this.completedMeetingList = res['completedMeetingList'];
          this.pendingMeetingList = res['pendingMeetingList'];
          this.counsellingRequestSentToMentor = res['counsellingRequestSentToMentor'];
          this.counsellingRequestReceivedAsMentor = res['counsellingRequestReceivedAsMentor'];
          this.mentorMeetingListWithStudent = res['mentorMeetingListWithStudent'];
          this.mentorTeacherStudentCounselling = res['mentorTeacherStudentCounselling'];
          this.mentorMeetingWithGuardian = res['mentorMeetingWithGuardian'];
          this.mentorMeetingWithCourseTeacher = res['mentorMeetingWithCourseTeacher'];
          this.mentorSemesterCourseList = res['mentorSemesterCourseList'];

          this.isShowData = false;
          this.printReport();
          // this.getTeacherLookupMentorStdList();
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }

  getTeacherLookupMentorStdList() {
    // this.isShowData = true;
    const myParams = new HttpParams()
      .append('teacherId', this.teacherId)
      .append('semesterId', this.selectedSemester);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-lookup/mentor-student-list',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.mentoringList = res;
          this.isShowData = false;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }


  printReport() {
    // this.isShowData = true;
    const myParams = new HttpParams()
      .append('teacherId', this.teacherId)
      .append('semesterId', this.selectedSemester);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-lookup/mentor-report',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.mentoringRpt = res;
          // this.isShowData = false;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }

  openMentoringRptDialog(): void {
    const searchObj = {teacherId: this.teacherId, semesterId: this.selectedSemester};
    const dialogRef = this.dialog.open(MentoringRptComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: searchObj
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

}
