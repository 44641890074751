import {Component, Inject, OnInit} from '@angular/core';
import {MwctInstructionDialogComponent} from '../../mwct-instruction-dialog/mwct-instruction-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-scsfm-instruction-dialog',
  templateUrl: './scsfm-instruction-dialog.component.html',
  styleUrls: ['./scsfm-instruction-dialog.component.css']
})
export class ScsfmInstructionDialogComponent implements OnInit {

  constructor( public dialog: MatDialog,
               public dialogRef: MatDialogRef<ScsfmInstructionDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
