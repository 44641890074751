import { Component, OnInit } from '@angular/core';
import {AppService} from '../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import * as XLSX from 'xlsx';
import {Toast, ToastsManager} from 'ng2-toastr';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TaInstructionDialogComponent} from './ta-instruction-dialog/ta-instruction-dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-teacher-access',
  templateUrl: './teacher-access.component.html',
  styleUrls: ['./teacher-access.component.css']
})
export class TeacherAccessComponent implements OnInit {

  completedStudentIdOrNameFilter: any = '';
  mentoringStudentIdOrNameFilter: any = '';

  studentListForMentoring: any = [];
  studentListForComplete: any = [];
  pendingCounsellingRequest: any = [];
  pendingProctorCounsellingRequest: any = [];
  pendingNextMeetings: any = [];

  selectedSemesterForSearch: any = '';
  semesterList: any = [];
  registeredStudentList: any = [];
  unRegisteredStudentList: any = [];
  isRegisteredChecked= false;
  isUnregisteredChecked= false;

  mentoringStdListColumn = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      suppressFilter: true,
      // template: `<a style="background: #006DD2; color: white; border: none; padding: 5px 5px;" target="_blank" data-action-type="viewMentoringStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></a>`
      template: `<button style="background: #006DD2; color: white; border: none; padding: 5px 5px;" data-action-type="viewMentoringStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></button>`
    },
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 210, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'DUE', field: 'dueAmount', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Proctorial Issue', field: 'proctorial', width: 100, sortable: true, unSortIcon: true},
    {headerName: 'Course Taken', field: 'CourseTaken', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'Expire', field: 'EXPIRE_DATE', width: 90, sortable: true, unSortIcon: true},
    // {headerName: 'Student Category', field: 'dropOutZone', width: 120, filter: 'agTextColumnFilter'},
    {headerName: 'Email', field: 'EMAIL', width: 210 },
    {headerName: 'Mobile', field: 'MOBILE', width: 120 },
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120 },
    // {headerName: 'Mother Mobile', field: 'motherMobile', width: 110, },
    {headerName: 'No.   of Mentoring', field: 'mentoring_count', width: 110 },
  ];

  completedStdListColumn = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      suppressFilter: true,
      // template: `<a style="font-size: 20px; color: #0066D3; cursor: pointer"></a>`
      template: ` <button style="background: #006DD2; color: white; border: none; padding: 5px 5px;" data-action-type="viewCompletedStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></button>`
    },
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 220, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'Email', field: 'EMAIL', width: 220, },
    {headerName: 'Mobile', field: 'MOBILE', width: 120, },
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120, },
    {headerName: 'Mother Mobile', field: 'motherMobile', width: 120, },
  ];






  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService, public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }
  ngOnInit() {
    this.appService.hasAccessToken();
    this.getStudentListForMentoring();
    this.getStudentListForComplete();
    this.getPendingCounsellingRequest();
    this.getPendingProctorCounsellingRequest();
    this.getNextPendingMeetings();
    this.getSemesterList();
  }


  getStudentListForMentoring() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/studentListForMentoring',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
         console.log(res);
          this.studentListForMentoring = res;
         if (this.studentListForMentoring.length > 0 ) {
           this.toastr.success('Student List for Mentoring Data Found!!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
             .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
         }else {
           this.toastr.error('Student List for Mentoring Not Found', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
             .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
             });
         }
          // (this.studentListForMentoring == null) ? this.studentListForMentoringLength = 0 : this.studentListForMentoringLength = this.studentListForMentoring.length ;
        },
        msg => {  console.error('error');    }
      );
  }
  getPendingCounsellingRequest() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentCounsellingRequestFromCourseTeacher/pendingCounsellingRequest',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.pendingCounsellingRequest = res;
          // (this.studentListForMentoring == null) ? this.studentListForMentoringLength = 0 : this.studentListForMentoringLength = this.studentListForMentoring.length ;
        },
        msg => {  console.error('error');    }
      );
  }
  getPendingProctorCounsellingRequest() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentCounsellingRequestFromProctor/pendingProctorCounsellingRequest',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          console.log(res);
          this.pendingProctorCounsellingRequest = res;
          // (this.studentListForMentoring == null) ? this.studentListForMentoringLength = 0 : this.studentListForMentoringLength = this.studentListForMentoring.length ;
        },
        msg => {  console.error('error');    }
      );
  }
  getNextPendingMeetings() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentNextMeeting/pendingMeeting',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.pendingNextMeetings = res;
          // (this.studentListForMentoring == null) ? this.studentListForMentoringLength = 0 : this.studentListForMentoringLength = this.studentListForMentoring.length ;
        },
        msg => {  console.error('error');    }
      );
  }

  getStudentListForComplete() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/studentListForComplete',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
        // console.log(res);
         this.studentListForComplete = res;
        },
        msg => { console.error('error'); }
      );
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {  console.error('error');   }
      );
  }

  getSelectedStudentList() {
    if (this.isRegisteredChecked) {
      this.getRegisteredStudentList();
    } else if (this.isUnregisteredChecked) {
      this.getUnregisteredStudentList();
    } else {
     console.error('Please select a check box item');
    }
  }


  openTaInstructionDialog(): void {
    const dialogRef = this.dialog.open(TaInstructionDialogComponent, {
      width: '60%',
      height: 'auto',
      disableClose: false,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  // data view on input after click
  public getSelectedDataForMentoringStdList(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const rowData = e.event.target.getAttribute('data-action-type');
      switch (rowData) {
        case 'viewMentoringStdList':
          return this.viewSelectedDataForMentoringStdList(data);
      }
    }
  }
  viewSelectedDataForMentoringStdList(data: any) {
    console.log('row', data);
    this.router.navigate([]).then(result => { window.open('/#/teacher-access/' + data.STUDENT_ID + '/single-student-mentoring', '_blank'); });
  }

  public getSelectedDataForCompletedStdList(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const rowData = e.event.target.getAttribute('data-action-type');
      switch (rowData) {
        case 'viewCompletedStdList':
          return this.viewSelectedDataForCompletedStdList(data);
      }
    }
  }

  viewSelectedDataForCompletedStdList(data: any) {
    console.log('row', data);
    this.router.navigate(['/teacher-access/' + data.STUDENT_ID + '/single-student-mentoring']);
  }


  getRegisteredStudentList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/registeredStudent/v1/registeredStudentList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          console.log(res);
          this.studentListForMentoring = res;
          if (this.studentListForMentoring.length > 0 ) {
            this.isRegisteredChecked = false;
            this.toastr.success('Registered Students for Mentoring Data Found!!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }else {
            this.isRegisteredChecked = false;
            this.toastr.error('Registered Students for Mentoring Not Found!!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
              .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
              });
          }
          // this.studentListForMentoring = res;
        },
        msg => {  console.error('error');    }
      );
  }

  getUnregisteredStudentList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/registeredStudent/v1/unRegisteredStudentList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          console.log(res);
          this.studentListForMentoring = res;
          if (this.studentListForMentoring.length > 0 ) {
            this.isUnregisteredChecked = false;
            this.toastr.success('Unregistered Students for Mentoring Data Found!!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
            this.studentListForMentoring = res;
          }else {
            this.isUnregisteredChecked = false;
            this.toastr.error('Unregistered Students for Mentoring Not Found!!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
              .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
              });
          }
          // this.studentListForMentoring = res;
        },
        msg => {  console.error('error');    }
      );
  }


  exportToExcel(): void {
    const params = {
      fileName: 'mentoring_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentListForMentoring);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }
}
