import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';

import 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Params, Router} from '@angular/router';

import {Toast, ToastsManager} from 'ng2-toastr/ng2-toastr';

import { environment } from '../../environments/environment';
import {AppService} from '../appService';


@Component({
  selector: 'app-assign-head-monitor-teacher',
  templateUrl: './assign-head-monitor-teacher.component.html',
  styleUrls: ['./assign-head-monitor-teacher.component.css']
})
export class AssignHeadMonitorTeacherComponent implements OnInit {
  headIdOrNameFilter: any = '';
  teacherIdOrNameFilter: any = '';
  alreadyAssignedTeacherIdOrNameFilter: any = '';

  employeeIdorNameToSearchHead = '';
  employeeIdorNameToSearchTeacher = '';
  headList: any = [];
  teacherList: any = [];
  selectedHead: any = {};
  selectedTeacherList: any = [];

  headIdAndNameToSearchAlreadyAssignedTeacherList= '';
  teacherIdAndNameToSearchAlreadyAssignedTeacherList= '';
  alreadyAssignedTeacherList: any= [];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }
  ngOnInit() {
    this.appService.hasAccessToken();
  }

  getTeacherListForHead() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchHead);
    this.httpClient.get(environment.apiUrl + 'assignHeadMonitorTeacher/employeeList',  {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.headList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getTeacherListForTeacher() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchTeacher);
    this.httpClient.get(environment.apiUrl + 'assignHeadMonitorTeacher/employeeList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.teacherList = res;
        },
        msg => {         console.error('error');    }
      );
  }

  selectHead(ah) {
    this.selectedHead = ah;
    // console.log(this.selectedHead);
  }

  selectTeacher(t) {
    this.selectedTeacherList.push(t);
    /*for (const te of this.selectedTeacherList) {   // console.log(te);   }*/
  }

  clearHeadAndTeacher() {
    this.selectedHead = {};
    this.selectedTeacherList = [];

  }
  clearSelectedHead() {
    this.selectedHead = {};
  }
  clearSingleSelectedTeacher(selected, index) {
    this.selectedTeacherList.splice(index, 1);
    /*for (let i int = 0; i > this.selectedTeacherList.length; i++) {
      if (this.selectedTeacherList[i].EMPLOYEE_ID === selected.EMPLOYEE_ID) {
        this.selectedTeacherList.splice(i, 1);
      }
    }*/
  }



  assignHeadToMonitorTeacher() {
    const tempArr = [];
    for (const te of this.selectedTeacherList)  {  tempArr.push(te.EMPLOYEE_ID);   }
    this.httpClient.post(environment.apiUrl + 'assignHeadMonitorTeacher/assignHead',
      {headEmployeeId: this.selectedHead.EMPLOYEE_ID, teacherEmployeeIdList: tempArr, },
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(  res => {
          // console.log(res);

          if (res['status'] === 'success') {
            this.toastr.success('Successfully Assigned!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {this.selectedHead = {}, this.selectedTeacherList = []; setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error('Assigned Failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }


  alreadyAssignedTeacherListSearchByHead() {
    const myParams = new HttpParams().append('headIdorName', this.headIdAndNameToSearchAlreadyAssignedTeacherList);
    this.httpClient.get(environment.apiUrl + 'assignHeadMonitorTeacher/teacherListByHead',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedTeacherList = res;
        },
        msg => {    console.error('error');    }
      );
  }
  alreadyAssignedTeacherListSearchByTeacher() {
    const myParams = new HttpParams().append('teacherIdorName', this.teacherIdAndNameToSearchAlreadyAssignedTeacherList);
    this.httpClient.get(environment.apiUrl + 'assignHeadMonitorTeacher/teacherListByTeacher',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedTeacherList = res;
        },
        msg => {    console.error('error');    }
      );
  }


  deleteAssignedHeadFromTeacher(headTeacherId) {
    const myParams = new HttpParams().append('headTeacherId', headTeacherId);
    this.httpClient.delete(environment.apiUrl + 'assignHeadMonitorTeacher/deleteAssignedHeadToTeacher',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success('Successfully Deleted!', 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => { this.alreadyAssignedTeacherListSearchByHead(); setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          } else {
            this.toastr.success('Delete Failed!', 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);   });
          }
        },
        msg => {    console.error('error');    }
      );
  }

}
