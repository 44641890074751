import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';
import {AscrfctDialogComponent} from '../../teacher-access/all-student-counselling-request-from-course-teacher/ascrfct-dialog/ascrfct-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-district-wise-student',
  templateUrl: './district-wise-student.component.html',
  styleUrls: ['./district-wise-student.component.css']
})
export class DistrictWiseStudentComponent implements OnInit {

  selectedDistrictForSearch: any = '';
  selectedSemesterForSearch: any = '';
  selectedDepartmentForSearch: any = '';
  studentDataFilter: any = '';
  completeMeetingListFilter: any = '';
  isShowData: boolean;
  districtList: any = [];
  semesterList: any = [];
  departmentList: any = [];
  studentData: any = [];
  completedMeetingList: any = [];


  constructor(private appService: AppService,
              public dialog: MatDialog,
              private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr); }


  ngOnInit() {
    this.appService.hasAccessToken();
    // this.route.params.subscribe( (params: Params) => {} );
    this.getDistrictList();
    this.getSemesterList();
    this.getDepartmentList();
  }


  getDistrictList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/districtList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.districtList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }
  getDepartmentList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/v1/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {  console.error('error');   }
      );
  }

  getDistrictWiseStudentList() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch).
    append('semesterID', this.selectedSemesterForSearch).append('district', this.selectedDistrictForSearch);
    this.isShowData = true;
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-report/district-wise-student-list',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          this.studentData = res;
          this.isShowData = false;
        },
        msg => {
          this.isShowData = false;
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  getCompleteMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedDistrictForSearch);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentCounsellingRequestFromCourseTeacher/completeMeetingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('res' , res);
          this.completedMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }
  isButtonDisable() {
    if (this.selectedDistrictForSearch && this.selectedSemesterForSearch && this.selectedDepartmentForSearch) {
      return false;
    }else {
      return true;
    }
  }


  counsellingStudent(pendingMeeting): void {
    const dialogRef = this.dialog.open(AscrfctDialogComponent, {
      width: 'auto',
      disableClose: true,
      data: {pendingMeeting: pendingMeeting}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.getPendingMeetingList();
      this.getCompleteMeetingList();
    });
  }
  exportToExcel(): void {
    const params = {
      fileName: 'district_wise_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }


  /*  openCrfctInstructionDialog(): void {
      const dialogRef = this.dialog.open(CrfctInstructionDialogComponent, {
        width: '60%',
        height: 'auto',
        disableClose: false,
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
      });
    }*/






}
