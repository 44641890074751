import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'idAndNameFilterPipe'
})
export class IdAndNameFilterPipe implements PipeTransform {

  transform(valueList: any, filterString: string, propertyName: string, propertyName2: string): any {

    if (valueList == null || filterString === '') {
      return valueList;
    }
    const resultArray = [];


    for (const item of valueList) {
      if (item[propertyName] === null || item[propertyName2] === null) {

      }else if (item[propertyName].toLowerCase().indexOf(filterString.toLowerCase()) !== -1 || item[propertyName2].toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
        resultArray.push(item);
      }
    }

 /*   for (const item of valueList) {
      if (item[propertyName] === null) {

      }else if (item[propertyName].toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
        resultArray.push(item);
      }
    }*/

 /*   searchText = searchText.toLowerCase();
    return items.filter( it => {
      return it.toLowerCase().includes(searchText);
    });
  }*/


    return resultArray;
  }

}



