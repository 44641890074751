import {Component, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Toast, ToastsManager} from 'ng2-toastr';
import {AppService} from '../../../appService';
import {HttpClient} from '@angular/common/http';
import {ConfirmSaveComponent} from '../../../confirm-dialog/confirm-save/confirm-save.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-counselling-request-from-mentor-dialog',
  templateUrl: './counselling-request-from-mentor-dialog.component.html',
  styleUrls: ['./counselling-request-from-mentor-dialog.component.css']
})
export class CounsellingRequestFromMentorDialogComponent implements OnInit {

  pendingMeeting: any = {};
  counselling_conduct_date: any = '';
  counselling_teacher_remarks: any = '';

  // counselling_conduct_time;
  next_meetingHour: any = '';
  next_meetingMinute: any = '';
  next_meetingampm: any = '';

  hourList: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  ampmList: any = ['am', 'pm'];

  // date filter
  date = new Date();
  // minDate = new Date();
  maxDate = new Date(
    this.date.getFullYear(),
    this.date.getMonth(),
    this.date.getDate()
  );

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<CounsellingRequestFromMentorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);

  }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.pendingMeeting = this.data.pendingMeeting;
    // console.log('previous: ', this.pendingMeeting);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateCounsellingInfo() {
    this.pendingMeeting.counselling_conduct_date = this.counselling_conduct_date.toLocaleDateString();
    this.pendingMeeting.counselling_teacher_remarks = this.counselling_teacher_remarks;
    this.pendingMeeting.counselling_conduct_time = this.next_meetingHour + ':' + this.next_meetingMinute + ' ' + this.next_meetingampm;

    // console.log('new: ', this.pendingMeeting);


    this.httpClient.put(environment.apiUrl + 'studentCounselling/requestFromMentor/updateMeeting',
      this.pendingMeeting,
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe( res => {
          // console.log(res);

          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {this.toastr.dismissToast(toast); this.dialogRef.close(); }, 2000); });
          }
          if (res['status'] === 'failed') {
            this.toastr.error(res['message'], 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );

  }



  updateCounsellingInfoWithConfirmation(): void {

    // console.log('opening confirm');

    const dialogRef = this.dialog.open(ConfirmSaveComponent, {
      width: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined ) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");

        if (result.confirmStatus === 'yes' ) {
          this.updateCounsellingInfo();
        }else {
          // console.log("not save");
        }

      }
    });
  }
}
