import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import {AppService} from '../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  studentListForMentoring: any = [];
  noGreenStudents=0
  noYellowStudents=0
  noRedStudent=0

  constructor( private appService: AppService,private route: ActivatedRoute,private httpClient: HttpClient,) {
  }
  employeeId:string;
  ngOnInit() {
    this.getStudentListForMentoring();
    this.appService.hasAccessToken();
    this.route.queryParams
      .filter(params => params.employeeId)
      .subscribe(params => {
        console.log(params);
        this.employeeId=params['employeeId'] // {order: "popular"}
      });
  }

  getStudentListForMentoring() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/studentListForMentoring',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
         this.studentListForMentoring = res;
         for (var studentInfo of this.studentListForMentoring) {
            switch (studentInfo['dropOutZone']) {
                case 'C':
                    this.noRedStudent+=1
                    break;
                case 'A':
                    this.noGreenStudents+=1
                    break;
                case 'B':
                    this.noYellowStudents+=1
                    break;
            }
          }
          // (this.studentListForMentoring == null) ? this.studentListForMentoringLength = 0 : this.studentListForMentoringLength = this.studentListForMentoring.length ;
        },
        msg => {  console.error('error');    }
      );
  }


}
