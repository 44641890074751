import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import {MatDialog} from '@angular/material/dialog';
import {ToastsManager} from 'ng2-toastr';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-mentor-not-assigned',
  templateUrl: './mentor-not-assigned.component.html',
  styleUrls: ['./mentor-not-assigned.component.css']
})
export class MentorNotAssignedComponent implements OnInit {
  selectedFacultyForSearch: any = '';
  selectedProgramForSearch: any = '';
  selectedDepartmentForSearch: any = '';
  isShowData: boolean;
  facultyList: any = [];
  programList: any = [];
  departmentList: any = [];
  studentData: any = [];
  constructor(private appService: AppService,
              public dialog: MatDialog, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) { }

  ngOnInit() {
    this.getFacultyList();
    // this.getProgramList();
    // this.getDepartmentList();
  }

  getFacultyList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/facultyList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.facultyList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getDepartmentList() {
    const myParams = new HttpParams().append('faculty', this.selectedFacultyForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
           // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getProgramList() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/program-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.programList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }
  getMentorNotAssignedStudentList() {
    const myParams = new HttpParams().append('faculty', this.selectedFacultyForSearch).
    append('department', this.selectedDepartmentForSearch).append('program', this.selectedProgramForSearch);
    this.isShowData = true;
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-report/mentor-not-assign-student-list',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          this.isShowData = false;
          this.studentData = res;
        },
        msg => {
          this.isShowData = false;
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  isButtonDisable() {
    if (this.selectedFacultyForSearch && this.selectedDepartmentForSearch && this.selectedProgramForSearch) {
      return false;
    }else {
      return true;
    }
  }

  exportToExcel(): void {
    const params = {
      fileName: 'mentor_not_assign_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }
}
