import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';

@Component({
  selector: 'app-counselling',
  templateUrl: './counselling.component.html',
  styleUrls: ['./counselling.component.css']
})
export class CounsellingComponent implements OnInit {
  studentId: any;
  isShowData: boolean;

  studentCounsellingList: any = [];

  constructor(private appService: AppService, private activatedRoute: ActivatedRoute, private router: Router,
              private httpClient: HttpClient, public toastr: ToastsManager) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.studentId = params['studentId'];
    });
    this.getStdCounsellingList();
  }

  getStdCounsellingList() {
    this.isShowData = true;
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-student-counselling/studentId',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.studentCounsellingList = res;
          this.isShowData = false;
        },
        msg => {
          console.error('error');
        }
      );
  }

}
