import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AppService} from '../../appService';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent implements OnInit {

  constructor(private appService: AppService,
              public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    // console.log(" in dialog ctrl init");
    // console.log(this.data);
  }

  yes(): void {
    this.dialogRef.close({confirmStatus: 'yes'});
  }

  no(): void {
    this.dialogRef.close({confirmStatus: 'no'});
  }

}
