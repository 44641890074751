import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-crtm-instruction-dialog',
  templateUrl: './crtm-instruction-dialog.component.html',
  styleUrls: ['./crtm-instruction-dialog.component.css']
})
export class CrtmInstructionDialogComponent implements OnInit {

  constructor( public dialog: MatDialog,
               public dialogRef: MatDialogRef<CrtmInstructionDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
