import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Toast, ToastsManager} from 'ng2-toastr';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material';
import {ConfirmSaveComponent} from '../../../confirm-dialog/confirm-save/confirm-save.component';
import {MwctInstructionDialogComponent} from './mwct-instruction-dialog/mwct-instruction-dialog.component';

@Component({
  selector: 'app-meeting-with-course-teacher',
  templateUrl: './meeting-with-course-teacher.component.html',
  styleUrls: ['./meeting-with-course-teacher.component.css']
})
export class MeetingWithCourseTeacherComponent implements OnInit {

  studentId: any = '';
  semesterId: any = '';
  counsellingTitle: any = '';
  counsellingDetail: any = '';
  selectedCourseSectionTeacher: any = {};
  pendingMeetingListFilter: any = '';
  completeMeetingListFilter: any = '';

  semesterList: any = [];
  courseSectionTeacherList: any = [];

  pendingMeetingList: any = [];
  completeMeetingList: any = [];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {

    this.toastr.setRootViewContainerRef(vcr);
  }


  ngOnInit() {
    this.appService.hasAccessToken();

    this.route.params.subscribe( (params: Params) => { this.studentId = params['studentId'];  }   );
    // console.log(this.studentId);

    this.getSemesterList();
    this.getPendingMeetingList();
    this.getCompleteMeetingList();
  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/teacherMeetingCourseTeacher/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getCourseSectionTeacherList() {

    if (this.semesterId !== '' && this.semesterId !== undefined) {

      const myParams = new HttpParams().append('studentId', this.studentId).append('semesterId', this.semesterId);
      this.httpClient.get(environment.apiUrl + 'teacherAccess/teacherMeetingCourseTeacher/courseSectionTeacherList', {
        headers: this.appService.getHeadersWithAccessToken(),
        params: myParams
      })
        .subscribe(res => {
            // console.log(res);
            this.courseSectionTeacherList = res;
          },
          msg => {
            console.error('error');
          }
        );

    } else {
      this.courseSectionTeacherList = [];
    }
  }

  clearField() {
    this.selectedCourseSectionTeacher = '';
    this.counsellingTitle = '';
    this.counsellingDetail = '';
  }

  saveMeetingInfo() {

      this.httpClient.post(environment.apiUrl + 'teacherAccess/teacherMeetingCourseTeacher/saveMeeting',
      {
        student_id: this.studentId,
        semester_id : this.semesterId ,
        counselling_title : this.counsellingTitle ,
        counselling_detail : this.counsellingDetail ,
        courseSection_id : this.selectedCourseSectionTeacher.COURSE_SECTION_ID ,
        section : this.selectedCourseSectionTeacher.SECTION_NAME ,
        course_code : this.selectedCourseSectionTeacher.COURSE_CODE ,
        course_title : this.selectedCourseSectionTeacher.COURSE_TITLE ,
        request_to_teacher_id : this.selectedCourseSectionTeacher.TEACHER_ID
      },
      {headers: this.appService.getHeadersWithAccessToken()})
        .subscribe( res => {
            // console.log(res);

            if (res['status'] === 'success') {
              this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
                .then((toast: Toast) => {this.clearField(); setTimeout(() => { this.toastr.dismissToast(toast);
                  this.getPendingMeetingList(); this.getCompleteMeetingList(); }, 2000); });
            }
            if (res['status'] === 'failed') {
              this.toastr.error(res['message'], 'Fail!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
                .then((toast: Toast) => { setTimeout(() => { this.toastr.dismissToast(toast); }, 5000);  });
            }
          },
          msg => {
            console.error(`Error: ${msg.status} ${msg.statusText}`);
            this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
              .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
              });
          }
        );

  }

  saveMeetingInfoWithConfirmation(): void {

    // console.log('opening dialog');

    const dialogRef = this.dialog.open(ConfirmSaveComponent, {
      width: 'auto',  data: {today: new Date()}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined ) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");
        if (result.confirmStatus === 'yes' ) {
          // console.log('save');
          this.saveMeetingInfo();
        }else {
          // console.log("not save");
        }
      }
    });
}


  // pending meeting list
  getPendingMeetingList() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/teacherMeetingCourseTeacher/pendingMeetingList',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.pendingMeetingList = res;
        },
        msg => {  console.error('error');    }
      );
  }

  // pending meeting list
  getCompleteMeetingList() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/teacherMeetingCourseTeacher/completeMeetingList',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.completeMeetingList = res;
        },
        msg => {  console.error('error');    }
      );
  }

  openmwctInstructionDialog(): void {
    const dialogRef = this.dialog.open(MwctInstructionDialogComponent, {
      width: '60%',
      height: 'auto',
      disableClose: false,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }


}
