import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AppService} from '../../appService';

@Component({
  selector: 'app-confirm-save',
  templateUrl: './confirm-save.component.html',
  styleUrls: ['./confirm-save.component.css']
})
export class ConfirmSaveComponent implements OnInit {

  constructor(private appService: AppService,
                 public dialogRef: MatDialogRef<ConfirmSaveComponent>,
                 @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    // console.log(" in dialog ctrl init");
    // console.log(this.data);
  }

  yes(): void {
    this.dialogRef.close({confirmStatus: 'yes'});
  }

  no(): void {
    this.dialogRef.close({confirmStatus: 'no'});
  }
}
