import { Component, OnInit } from '@angular/core';
import {AppService} from '../appService';

@Component({
  selector: 'app-mentoring-report',
  templateUrl: './mentoring-report.component.html',
  styleUrls: ['./mentoring-report.component.css']
})
export class MentoringReportComponent implements OnInit {
activeTabNumber= 1;
  constructor(private appService: AppService ) { }

  ngOnInit() {
  }

  activeTab(tabNo) {
    this.activeTabNumber = tabNo;
  }
  hasRole(role) {
    // console.log('has role ', this.appService.hasAccess(role));
    return this.appService.hasAccess(role);
  }

  isLoggedin() {
    return this.appService.isLoggedin();
  }

}
