import {Component, OnInit, Injectable, ViewContainerRef} from '@angular/core';
import {Headers, Http, Response} from '@angular/http';
import 'rxjs/Rx';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Params, Router} from '@angular/router';

import {Toast, ToastsManager} from 'ng2-toastr/ng2-toastr';

import {AppService} from './appService';
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  name = '';
  employeeId = '';

  constructor(private appService: AppService, private router: Router,
              private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }
  isExpanded = false;
  ngOnInit() {
  }
  toggleMenu() {
    this.isExpanded = !this.isExpanded;
  }
  showUserinfo() {

    // console.log(this.appService.getAccessToken());
    // console.log(this.appService.getAccessTokenInfo());

    if (this.appService.getAccessTokenInfo() == null) {
      return true;
    } else {
      this.employeeId = this.appService.getAccessTokenInfo().employeeId;
      this.name = this.appService.getAccessTokenInfo().name;
      return false;
    }
  }

  logout() {

    const myParams = new HttpParams().append('accessToken', this.appService.getAccessToken());
    this.httpClient.get(environment.apiUrl + 'logout',  {headers: this.appService.getHeadersWithoutAccessToken(), params: myParams})
      .subscribe(  res => {
          if (res['message'] === 'success') {
            this.toastr.success('Logout Success!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
             this.appService.deleteAccessToken();
             this.router.navigate(['/login']);
          }
          if (res['message'] === 'failed') {
            this.toastr.error('Logout failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Logout Failed!', 'Oops!!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }

  hasRole(role) {
    // console.log('has role ', this.appService.hasAccess(role));
    return this.appService.hasAccess(role);
  }

  isLoggedin() {
    return this.appService.isLoggedin();
  }

}




