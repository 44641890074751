import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

import {AssignAssociateHeadMonitorTeacherComponent} from './assign-associate-head-monitor-teacher/assign-associate-head-monitor-teacher.component';
import {AssignDeanMonitorAssociateHeadComponent} from './assign-dean-monitor-associate-head/assign-dean-monitor-associate-head.component';
import {AssignHeadMonitorTeacherComponent} from './assign-head-monitor-teacher/assign-head-monitor-teacher.component';
import {AssignTeacherMonitorStudentComponent} from './assign-teacher-monitor-student/assign-teacher-monitor-student.component';
import {AssociateHeadAccessComponent} from './associate-head-access/associate-head-access.component';
import {DeanAccessComponent} from './dean-access/dean-access.component';
import {HeadAccessComponent} from './head-access/head-access.component';
import {TeacherAccessComponent} from './teacher-access/teacher-access.component';
import {SingleStudentMentoringComponent} from './teacher-access/single-student-mentoring/single-student-mentoring.component';
import {MeetingWithStudentComponent} from './teacher-access/single-student-mentoring/meeting-with-student/meeting-with-student.component';
import {MeetingWithGuardianComponent} from './teacher-access/single-student-mentoring/meeting-with-guardian/meeting-with-guardian.component';
import {LoginComponent} from './login/login.component';
import {IndexComponent} from './index/index.component';
import {MeetingWithCourseTeacherComponent} from './teacher-access/single-student-mentoring/meeting-with-course-teacher/meeting-with-course-teacher.component';
import {AssignDeanMonitorHeadComponent} from './assign-dean-monitor-head/assign-dean-monitor-head.component';
import {SingleStudentMentoringByHeadComponent} from './head-access/single-student-mentoring-by-head/single-student-mentoring-by-head.component';
import {MeetingWithStudentByHeadComponent} from './head-access/single-student-mentoring-by-head/meeting-with-student-by-head/meeting-with-student-by-head.component';
import {AssignTeacherMonitorAssignedStudentComponent} from './assign-teacher-monitor-assigned-student/assign-teacher-monitor-assigned-student.component';
import {AssignTeacherMonitorUnassignedStudentComponent} from './assign-teacher-monitor-unassigned-student/assign-teacher-monitor-unassigned-student.component';
import {RegisteredStudentComponent} from './teacher-access/registered-student/registered-student.component';
import {RegisteredStudentByHeadComponent} from './head-access/registered-student-by-head/registered-student-by-head.component';
import {RegisteredStudentByAssociateheadComponent} from './associate-head-access/registered-student-by-associatehead/registered-student-by-associatehead.component';
import {SingleStudentMentoringByAssociateheadComponent} from './associate-head-access/single-student-mentoring-by-associatehead/single-student-mentoring-by-associatehead.component';
import {MeetingWithCourseTeacherByHeadComponent} from './head-access/single-student-mentoring-by-head/meeting-with-course-teacher-by-head/meeting-with-course-teacher-by-head.component';
import {MeetingWithGuardianByHeadComponent} from './head-access/single-student-mentoring-by-head/meeting-with-guardian-by-head/meeting-with-guardian-by-head.component';
import {MeetingWithCourseTeacherByAssociateheadComponent} from './associate-head-access/single-student-mentoring-by-associatehead/meeting-with-course-teacher-by-associatehead/meeting-with-course-teacher-by-associatehead.component';
import {MeetingWithGuardianByAssociateheadComponent} from './associate-head-access/single-student-mentoring-by-associatehead/meeting-with-guardian-by-associatehead/meeting-with-guardian-by-associatehead.component';
import {MeetingWithStudentByAssociateheadComponent} from './associate-head-access/single-student-mentoring-by-associatehead/meeting-with-student-by-associatehead/meeting-with-student-by-associatehead.component';
import {TeacherAccessByDeanComponent} from './dean-access/teacher-access-by-dean/teacher-access-by-dean.component';
import {RegisteredStudentByDeanComponent} from './dean-access/teacher-access-by-dean/registered-student-by-dean/registered-student-by-dean.component';
import {SingleStudentMentoringByDeanComponent} from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/single-student-mentoring-by-dean.component';
import {MeetingWithCourseTeacherByDeanComponent} from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/meeting-with-course-teacher-by-dean/meeting-with-course-teacher-by-dean.component';
import {MeetingWithGuardianByDeanComponent} from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/meeting-with-guardian-by-dean/meeting-with-guardian-by-dean.component';
import {MeetingWithStudentByDeanComponent} from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/meeting-with-student-by-dean/meeting-with-student-by-dean.component';
import {PanelAccessComponent} from './panel-access/panel-access.component';
import {TeacherAccessByPanelComponent} from './panel-access/teacher-access-by-panel/teacher-access-by-panel.component';
import {RegisteredStudentByPanelComponent} from './panel-access/teacher-access-by-panel/registered-student-by-panel/registered-student-by-panel.component';
import {SingleStudentMentoringByPanelComponent} from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/single-student-mentoring-by-panel.component';
import {MeetingWithStudentByPanelComponent} from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/meeting-with-student-by-panel/meeting-with-student-by-panel.component';
import {MeetingWithGuardianByPanelComponent} from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/meeting-with-guardian-by-panel/meeting-with-guardian-by-panel.component';
import {MeetingWithCourseTeacherByPanelComponent} from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/meeting-with-course-teacher-by-panel/meeting-with-course-teacher-by-panel.component';
import {StudentCounsellingComponent} from './student-counselling/student-counselling.component';

import {CounsellingRequestToMentorComponent} from './student-counselling/counselling-request-to-mentor/counselling-request-to-mentor.component';
import {CounsellingRequestFromMentorComponent} from './student-counselling/counselling-request-from-mentor/counselling-request-from-mentor.component';
import {StudentCounsellingShowFromMentorComponent} from './teacher-access/single-student-mentoring/meeting-with-course-teacher/student-counselling-show-from-mentor/student-counselling-show-from-mentor.component';
import {CounsellingRequestFromCourseTeacherComponent} from './teacher-access/single-student-mentoring/meeting-with-course-teacher/counselling-request-from-course-teacher/counselling-request-from-course-teacher.component';
import {RequestFromCourseTeacherByPanelComponent} from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/request-from-course-teacher-by-panel/request-from-course-teacher-by-panel.component';
import {AdvisingTargetSettingAndKpiByTeacherComponent} from './teacher-access/advising-target-setting-and-kpi-by-teacher/advising-target-setting-and-kpi-by-teacher.component';
import {AdvisingTargetSettingAndKpiByHeadComponent} from './head-access/advising-target-setting-and-kpi-by-head/advising-target-setting-and-kpi-by-head.component';
import {AdvisingTargetSettingAndKpiByDeanComponent} from './dean-access/advising-target-setting-and-kpi-by-dean/advising-target-setting-and-kpi-by-dean.component';
import {AllStudentCounsellingRequestFromCourseTeacherComponent} from './teacher-access/all-student-counselling-request-from-course-teacher/all-student-counselling-request-from-course-teacher.component';
import {AllStudentNextMeetingComponent} from './teacher-access/all-student-next-meeting/all-student-next-meeting.component';
import {DeveloperComponent} from './developer/developer.component';
import {SuperAccessComponent} from './super-access/super-access.component';
import {CounsellingListByTeacherComponent} from './panel-access/counselling-list-by-teacher/counselling-list-by-teacher.component';
import {StudentLookupComponent} from './student-lookup/student-lookup.component';
import {SemesterResultComponent} from './student-lookup/semester-result/semester-result.component';
import {ClassAttendanceComponent} from './student-lookup/class-attendance/class-attendance.component';
import {CounsellingComponent} from './student-lookup/counselling/counselling.component';
import {TeacherLookupComponent} from './teacher-lookup/teacher-lookup.component';
import {MentorAssessmentComponent} from './mentor-assessment/mentor-assessment.component';
import {ProctorialRequestToMentorComponent} from './student-counselling/protorial-request-to-mentor/proctorial-request-to-mentor.component';
import {ProctorialRequestFromMentorComponent} from './student-counselling/proctorial-request-from-mentor/proctorial-request-from-mentor.component';
import {AllStudentCounsellingRequestFromProctorComponent} from './teacher-access/all-student-counselling-request-from-proctor/all-student-counselling-request-from-proctor.component';
import {MentorNotAssignedComponent} from './mentoring-report/mentor-not-assigned/mentor-not-assigned.component';
import {StudentAttendanceAndCgpaComponent} from './mentoring-report/student-attendance-and-cgpa/student-attendance-and-cgpa.component';
import {DistrictWiseStudentComponent} from './mentoring-report/district-wise-student/district-wise-student.component';
import {MentoringReportComponent} from './mentoring-report/mentoring-report.component';
import {ProctorAccessComponent} from './proctor-access/proctor-access/proctor-access.component';
import {MentorMeetingWithGaurdianComponent} from './mentoring-report/mentor-meeting-with-gaurdian/mentor-meeting-with-gaurdian.component';
import {MentorMeetingWithCourseTeacherComponent} from './mentoring-report/mentor-meeting-with-course-teacher/mentor-meeting-with-course-teacher.component';




/*
export const appRoutes: Routes = [{
  // path: 'login',
  // component: LoginComponent
  path: 'login',
  loadChildren: 'app/login/login.module#LoginModule'
}, {
  // path: '',
  // canActivate: [ IsLoggedInGuard ],
  // component: DashboardComponent
  path: '',
  canActivate: [ IsLoggedInGuard ],
  loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
}, {
  // path: 'articles/:sourceId/:sortBy',
  // component: ArticlesComponent
  path: 'articles',
  loadChildren: 'app/articles/articles.module#ArticlesModule'
}];*/


// routing
export const appRoutes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},

  {path: 'assign-associate-head-monitor-teacher', component: AssignAssociateHeadMonitorTeacherComponent},
  {path: 'assign-dean-monitor-associate-head', component: AssignDeanMonitorAssociateHeadComponent},
  {path: 'assign-dean-monitor-head', component: AssignDeanMonitorHeadComponent},
  {path: 'assign-head-monitor-teacher', component: AssignHeadMonitorTeacherComponent},
  {path: 'assign-teacher-monitor-student', component: AssignTeacherMonitorStudentComponent},
  {path: 'assign-teacher-monitor-assigned-student', component: AssignTeacherMonitorAssignedStudentComponent},
  {path: 'assign-teacher-monitor-unassigned-student', component: AssignTeacherMonitorUnassignedStudentComponent},

  {path: 'superAccess', component: SuperAccessComponent},

  {path: 'panel-access', component: PanelAccessComponent},
  {path: 'mentoring-report/mentor-not-assigned', component: MentorNotAssignedComponent},
  {path: 'mentoring-report/student-attendance-and-cgpa', component: StudentAttendanceAndCgpaComponent},
  {path: 'mentoring-report/district-wise-student', component: DistrictWiseStudentComponent},
  {path: 'mentoring-report/meeting-with-guardian', component: MentorMeetingWithGaurdianComponent},
  {path: 'mentoring-report/meeting-with-course-teacher', component: MentorMeetingWithCourseTeacherComponent},
  {path: 'mentoring-report', component: MentoringReportComponent},
  {path: 'panel-access/:semesterId/:teacherId/counsellingList', component: CounsellingListByTeacherComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access', component: TeacherAccessByPanelComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access/:teacherId/registered-student-by-panel', component: RegisteredStudentByPanelComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access/:studentId/:teacherId/single-student-mentoring', component: SingleStudentMentoringByPanelComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access/:studentId/:teacherId/single-student-mentoring/meetingWithStudent', component: MeetingWithStudentByPanelComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access/:studentId/:teacherId/single-student-mentoring/meetingWithGuardian', component: MeetingWithGuardianByPanelComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access/:studentId/:teacherId/single-student-mentoring/meetingWithCourseTeacher', component: MeetingWithCourseTeacherByPanelComponent},
  {path: 'panel-access/:headOrAssociateHeadId/:designation/teacher-access/:studentId/:teacherId/single-student-mentoring/requestFromCourseTeacher', component: RequestFromCourseTeacherByPanelComponent},

  {path: 'student-lookup', component: StudentLookupComponent},
  {path: 'student-lookup/semester-result/:studentId', component: SemesterResultComponent},
  {path: 'student-lookup/class-attendance/:studentId', component: ClassAttendanceComponent},
  {path: 'student-lookup/counselling/:studentId', component: CounsellingComponent},

  {path: 'dean-access', component: DeanAccessComponent},
  {path: 'dean-access/advising-target-setting-and-kpi-by-dean', component: AdvisingTargetSettingAndKpiByDeanComponent},
  {path: 'dean-access/:teacherId/teacher-access', component: TeacherAccessByDeanComponent},
  {path: 'dean-access/:teacherId/teacher-access/registered-student-by-dean', component: RegisteredStudentByDeanComponent},
  {path: 'dean-access/:studentId/:teacherId/teacher-access/single-student-mentoring', component: SingleStudentMentoringByDeanComponent},
  {path: 'dean-access/:studentId/:teacherId/teacher-access/single-student-mentoring/meetingWithStudent', component: MeetingWithStudentByDeanComponent},
  {path: 'dean-access/:studentId/:teacherId/teacher-access/single-student-mentoring/meetingWithGuardian', component: MeetingWithGuardianByDeanComponent},
  {path: 'dean-access/:studentId/:teacherId/teacher-access/single-student-mentoring/meetingWithCourseTeacher', component: MeetingWithCourseTeacherByDeanComponent},


  {path: 'head-access', component: HeadAccessComponent},
  {path: 'head-access/advising-target-setting-and-kpi-by-head', component: AdvisingTargetSettingAndKpiByHeadComponent},
  {path: 'head-access/:teacherId/registered-student-by-head', component: RegisteredStudentByHeadComponent},
  {path: 'head-access/:studentId/:teacherId/single-student-mentoring', component: SingleStudentMentoringByHeadComponent},
  {path: 'head-access/:studentId/:teacherId/single-student-mentoring/meetingWithStudent', component: MeetingWithStudentByHeadComponent},
  {path: 'head-access/:studentId/:teacherId/single-student-mentoring/meetingWithGuardian', component: MeetingWithGuardianByHeadComponent},
  {path: 'head-access/:studentId/:teacherId/single-student-mentoring/meetingWithCourseTeacher', component: MeetingWithCourseTeacherByHeadComponent},


  {path: 'associate-head-access', component: AssociateHeadAccessComponent},
  {path: 'associate-head-access/:teacherId/registered-student-by-associatehead', component: RegisteredStudentByAssociateheadComponent},
  {path: 'associate-head-access/:studentId/:teacherId/single-student-mentoring', component: SingleStudentMentoringByAssociateheadComponent},
  {path: 'associate-head-access/:studentId/:teacherId/single-student-mentoring/meetingWithStudent', component: MeetingWithStudentByAssociateheadComponent},
  {path: 'associate-head-access/:studentId/:teacherId/single-student-mentoring/meetingWithGuardian', component: MeetingWithGuardianByAssociateheadComponent},
  {path: 'associate-head-access/:studentId/:teacherId/single-student-mentoring/meetingWithCourseTeacher', component: MeetingWithCourseTeacherByAssociateheadComponent},


  /*{path: 'teacher-access', component: TeacherAccessComponent, children: [
    {path: 'single-student-mentoring/:studentId', component: SingleStudentMentoringComponent}
  ]},*/

  {path: 'teacher-access', component: TeacherAccessComponent},
  {path: 'teacher-access/advising-target-setting-and-kpi-by-teacher', component: AdvisingTargetSettingAndKpiByTeacherComponent},
  {path: 'teacher-access/all-student-counselling-request-from-course-teacher', component: AllStudentCounsellingRequestFromCourseTeacherComponent},
  {path: 'teacher-access/all-student-next-meeting', component: AllStudentNextMeetingComponent},
  {path: 'teacher-access/registered-student', component: RegisteredStudentComponent},
  {path: 'teacher-access/:studentId/single-student-mentoring', component: SingleStudentMentoringComponent},
  {path: 'teacher-access/:studentId/single-student-mentoring/meetingWithStudent', component: MeetingWithStudentComponent},
  {path: 'teacher-access/:studentId/single-student-mentoring/meetingWithGuardian', component: MeetingWithGuardianComponent},
  {path: 'teacher-access/:studentId/single-student-mentoring/meetingWithCourseTeacher', component: MeetingWithCourseTeacherComponent},
  {path: 'teacher-access/counselling-request-from-proctor', component: AllStudentCounsellingRequestFromProctorComponent},
  {path: 'teacher-access/:studentId/single-student-mentoring/meetingWithCourseTeacher/student-counselling-show-from-mentor', component: StudentCounsellingShowFromMentorComponent},
  {path: 'teacher-access/:studentId/single-student-mentoring/meetingWithCourseTeacher/counselling-request-from-course-teacher', component: CounsellingRequestFromCourseTeacherComponent},


  {path: 'student-counselling', component: StudentCounsellingComponent},
  {path: 'student-counselling/counselling-request-to-mentor', component: CounsellingRequestToMentorComponent},
  // {path: 'proctor-access/proctorial-request-from-mentor', component: ProctorialRequestFromMentorComponent},
  // {path: 'proctor-access/proctorial-request-to-mentor', component: ProctorialRequestToMentorComponent},
  {path: 'student-counselling/counselling-request-from-mentor', component: CounsellingRequestFromMentorComponent},
  {path: 'developers', component: DeveloperComponent},

  {path: 'teacher-lookup', component: TeacherLookupComponent},
  {path: 'mentor-assessment', component: MentorAssessmentComponent},
  {path: 'proctor-access', component: ProctorAccessComponent},

 /* {path: 'student-counselling', component: StudentCounsellingComponent, children: [
      {path: 'counselling-request-to-mentor', component: CounsellingRequestToMentorComponent},
      {path: 'counselling-request-from-mentor', component: CounsellingRequestFromMentorComponent}
    ]},*/

];
