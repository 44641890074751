import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {ProctorCounsellingRequestDialogComponent} from './proctor-counselling-request-dialog/proctor-counselling-request-dialog.component';

@Component({
  selector: 'app-all-student-counselling-request-from-proctor',
  templateUrl: './all-student-counselling-request-from-proctor.component.html',
  styleUrls: ['./all-student-counselling-request-from-proctor.component.css']
})
export class AllStudentCounsellingRequestFromProctorComponent implements OnInit {
  selectedSemesterForSearch: any = '';
  pendingMeetingListFilter: any = '';
  completeMeetingListFilter: any = '';

  semesterList: any = [];
  pendingMeetingList: any = [];
  completedMeetingList: any = [];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr); }


  ngOnInit() {
    this.appService.hasAccessToken();
    // this.route.params.subscribe( (params: Params) => {} );
    this.getSemesterList();

  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getPendingMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentCounsellingRequestFromProctor/pendingMeetingList',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          this.pendingMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  getCompleteMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentCounsellingRequestFromProctor/completeMeetingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('res' , res);
          this.completedMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }


  counsellingStudent(pendingMeeting): void {
    const dialogRef = this.dialog.open(ProctorCounsellingRequestDialogComponent, {
      width: 'auto',
      disableClose: true,
      data: {pendingMeeting: pendingMeeting}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.getPendingMeetingList();
      this.getCompleteMeetingList();
    });
  }

}
