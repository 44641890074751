import { Component, OnInit } from '@angular/core';
import {ToastsManager} from 'ng2-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../appService';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-panel-access',
  templateUrl: './panel-access.component.html',
  styleUrls: ['./panel-access.component.css']
})
export class PanelAccessComponent implements OnInit {
  deanIdOrNameFilter: any = '';
  headIdOrNameFilter: any = '';
  associateHeadIdOrNameFilter: any = '';
  teacherIdOrNameFilter: any = '';
  semesterIdForCounselling: any;

  deanList: any = [];
  selectedDeanId: any = '';
  selectedDeanName: any = '';
  headList: any = [];
  associateHeadList: any = [];
  semesterList: any = [];
  teacherStudentCounsellingList: any = [];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.getDeanList();
    this.getSemesterList();
  }


  getDeanList() {
    this.httpClient.get(environment.apiUrl + 'panelAccess/deanList',  {headers: this.appService.getHeadersWithAccessToken() })
      .subscribe(  res => {
          // console.log(res);
          this.deanList = res;
        },
        msg => {    console.error('error');    }
      );
  }


  getHeadAndAssosiateHeadList(dean_id, dean_first_name) {

    this.selectedDeanId = dean_id;
    this.selectedDeanName = dean_first_name;

    this.getHeadListForSelectedDean(dean_id);
    this.associateHeadListForSelectedDean(dean_id);
  }

  getHeadListForSelectedDean(dean_id) {
    const myParams = new HttpParams().append('deanId', dean_id);
    this.httpClient.get(environment.apiUrl + 'panelAccess/headList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.headList = res;
        },
        msg => {  console.error('error');    }
      );
  }

  associateHeadListForSelectedDean(dean_id) {
    const myParams = new HttpParams().append('deanId', dean_id);
    this.httpClient.get(environment.apiUrl + 'panelAccess/associateHeadList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.associateHeadList = res;
        },
        msg => {  console.error('error');    }
      );
  }


  gotoTeacherListForSelectedHeadOrAssociateHead(employee_id, employee_first_name, designation) {

    // this.selectedTeacherId = teacher_id;
    // this.selectedTeacherName = teacher_first_name;
    //   this.selectedDesignation = designation;

     this.router.navigate(['panel-access/' + employee_id + '/' + designation + '/teacher-access']);
  }


  getSemesterList() {
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
    })
      .subscribe(res => {
          console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  getTeacherStudentCounselling() {
    const myParams = new HttpParams().append('semesterId', this.semesterIdForCounselling);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-student-counselling/semester', {
      headers: this.appService.getHeadersWithAccessToken(), params: myParams
    })
      .subscribe(res => {
          console.log(res);
          this.teacherStudentCounsellingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  goToCounsellingListByTeacher(semesterId, teacherId) {
    this.router.navigate(['panel-access/' + semesterId + '/' + teacherId + '/counsellingList']);
  }



}
