import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {MatDialog} from '@angular/material';
import {AppService} from '../../appService';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CounsellingRequestFromMentorDialogComponent} from './counselling-request-from-mentor-dialog/counselling-request-from-mentor-dialog.component';
import {CrfmInstructionDialogComponent} from './crfm-instruction-dialog/crfm-instruction-dialog.component';

@Component({
  selector: 'app-counselling-request-from-mentor',
  templateUrl: './counselling-request-from-mentor.component.html',
  styleUrls: ['./counselling-request-from-mentor.component.css']
})
export class CounsellingRequestFromMentorComponent implements OnInit {


  selectedSemester: any = '';
  selectedCourseSection: any = '';

  pendingMeetingListFilter: any = '';
  completeMeetingListFilter: any = '';

  semesterList: any = [];
  courseSectionList: any = [];
  pendingMeetingList: any = [];
  completeMeetingList: any = [];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient) { }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterList();
  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'studentCounselling/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getCourseSectionList(selectedSemesterId) {
    const myParams = new HttpParams().append('semesterId', selectedSemesterId);
    this.httpClient.get(environment.apiUrl + 'studentCounselling/courseSectionList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams})
      .subscribe(res => {
          // console.log(res);
          this.courseSectionList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  showPendingMeetingList() {
    const myParams = new HttpParams()
      .append('semesterId', this.selectedSemester)
      .append('courseSectionId', this.selectedCourseSection);
    this.httpClient.get(environment.apiUrl + 'studentCounselling/requestFromMentor/pendingMeetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams})
      .subscribe(res => {
          // console.log('hellooooo');
           console.log(res);
          this.pendingMeetingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  ShowCompleteMeetingList() {
    const myParams = new HttpParams()
      .append('semesterId', this.selectedSemester)
      .append('courseSectionId', this.selectedCourseSection);
    this.httpClient.get(environment.apiUrl + 'studentCounselling/requestFromMentor/completeMeetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams})
      .subscribe(res => {
          // console.log('ddddddddd');
          // console.log(res);
          this.completeMeetingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  counsellingStudent(pendingMeeting): void {
    const dialogRef = this.dialog.open(CounsellingRequestFromMentorDialogComponent, {
      width: 'auto',
      disableClose: true,
      data: {pendingMeeting: pendingMeeting}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.showPendingMeetingList();
      this.ShowCompleteMeetingList();
    });
  }


  openCrfmInstructionDialog(): void {
    const dialogRef = this.dialog.open(CrfmInstructionDialogComponent, {
      width: '60%',
      height: 'auto',
      disableClose: false,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }


}
