import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CourseAttendanceDialogComponent} from './course-attendance-dialog/course-attendance-dialog.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import {ToastsManager} from 'ng2-toastr';


@Component({
  selector: 'app-class-attendance',
  templateUrl: './class-attendance.component.html',
  styleUrls: ['./class-attendance.component.css']
})
export class ClassAttendanceComponent implements OnInit {
  studentId: any;
  isShowData: boolean;

  semesterWiseAttendanceList: any = [];


  constructor( public dialog: MatDialog, private appService: AppService, private router: Router,
               private httpClient: HttpClient, public toastr: ToastsManager, private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.studentId = params['studentId'];
    });
    console.log(this.studentId);
    this.getSemesterWiseAttendanceList();
  }

  getSemesterWiseAttendanceList() {
    this.isShowData = true;

    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/student-attendance-list',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(  res => {
          console.log(res);
          this.semesterWiseAttendanceList = res;
          this.isShowData = false;
        },
        msg => {    console.error('error');    }
      );
  }


  openCourseWiseClassAttdDialog(data): void {
    const dialogRef = this.dialog.open(CourseAttendanceDialogComponent, {
      width: '40%',
      height: '90%',
      disableClose: false,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

}
