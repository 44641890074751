import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-single-student-mentoring-by-panel',
  templateUrl: './single-student-mentoring-by-panel.component.html',
  styleUrls: ['./single-student-mentoring-by-panel.component.css']
})
export class SingleStudentMentoringByPanelComponent implements OnInit {
  summaryResultCourseCodeOrTitleFilter: any = '';
  detailsResultCourseCodeOrTitleFilter: any = '';
  attendanceCourseCodeOrTitleFilter: any = '';

  headOrAssociateHeadId: any = '';
  designation: any = '';
  teacherId: any = '';
  studentId: any = '';

  attendanceList: any = [];
  singleStudentDetails: any = {};
  semesterList: any = [];
  semesterIdToSearchSingleStudentResult = '';
  semesterIdToSearchSingleStudentAttendance = '';

  brifDescription: any = '';
  personalInterest: any = '';
  initialProblem: any = '';
  strangthOfStudent: any = '';
  weaknessOfStudent: any = '';
  additionalInfoOfStudent: any = '';
  singleStudentPaymentHistory: any = {};
  singleStudentCounsellingHistory: any = {};
  singleStudentProctorialCounsellingHistory: any = {};
  singleStudentResultSummery: any = {};
  singleStudentPaymentLedgerSummery: any = {};
  semesterIdToSearchSingleStudentResultDetails = '';
  singleStudentResultDetailsList: any = [];
  singleStudentResultList: any = [];
  singleStudentSgpaList: any = [];
  photograph: any = '';


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();

    this.route.params.subscribe((params: Params) => {
      this.headOrAssociateHeadId = params['headOrAssociateHeadId'];
      this.designation = params['designation'];
      this.studentId = params['studentId'];
      this.teacherId = params['teacherId'];
    });

    // console.log(this.headOrAssociateHeadId);
    // console.log(this.designation);
    // console.log(this.studentId);
    // console.log(this.teacherId);

    this.getSingleStudentDetails();
    this.getSingleStudentObservation();
    this.getSemesterList();
    this.getSingleStudentResultSummery();
    this.getSingleStudentPaymentLedgerSummery();
    this.getSingleStudentSgpaList();
    this.getSingleStudentPaymentHistory();
    this.getSingleStudentCounsellingHistory();
    this.getSingleStudentProctorialCounsellingHistory();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentObservation() {
    const myParams = new HttpParams().append('studentId', this.studentId).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentObservation', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);

          this.brifDescription = res['brifDescription'];
          this.personalInterest = res['personalInterest'];
          this.initialProblem = res['initialProblem'];
          this.strangthOfStudent = res['strangthOfStudent'];
          this.weaknessOfStudent = res['weaknessOfStudent'];
          this.additionalInfoOfStudent = res['additionalInfoOfStudent'];
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPhotograph(personId) {
    // console.log('personId ', personId);

    const myParams = new HttpParams().append('personId', personId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentPhotograph', {
      headers: this.appService.getHeadersWithAccessToken(),
      responseType: 'text',
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.photograph = 'data:image/jpeg;base64,' + res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPaymentHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentRecentPayment', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentCounsellingHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentProctorialCounsellingHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentProctorialCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentProctorialCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentResultSummery() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentResultSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentSgpaList() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentSgpaList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentSgpaList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPaymentLedgerSummery() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentPaymentLedgerSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentLedgerSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentDetails() {
    const myParams = new HttpParams().append('studentId', this.studentId).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentDetails = res;
          // console.log(res);

          if ( res !== null || res !== undefined ) {
            this.getSingleStudentPhotograph(res['PERSON_ID']);
          }

        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultDetailsList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResultDetails).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentResultDetailsList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultDetailsList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResult).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentResultList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }



  getAttendanceList() {
    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentAttendance).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentAttendanceList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.attendanceList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getPresentAttendanceTotal(attendance) {
    let count = 0;
    const objectKey = Object.keys(attendance);
    for (let i = 0; i < objectKey.length; i++) {
      if (objectKey[i].startsWith('A')) {
        const paramValue = attendance[objectKey[i]];
        if (paramValue) { count += 1; }
      }
    }
    return count;
  }


}
