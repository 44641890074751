import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';

@Component({
  selector: 'app-counselling-list-by-teacher',
  templateUrl: './counselling-list-by-teacher.component.html',
  styleUrls: ['./counselling-list-by-teacher.component.css']
})
export class CounsellingListByTeacherComponent implements OnInit {
  semesterId: any;
  teacherId: any;
  studentCounsellingList: any = [];

  constructor(private appService: AppService, private localStorage: LocalStorageService, private sessionStorage: SessionStorageService,
              private route: ActivatedRoute, private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.semesterId = params['semesterId'];
      this.teacherId = params['teacherId'];
    });
    this.getStdCounsellingList();
  }

  getStdCounsellingList() {
    const myParams = new HttpParams().append('semesterId', this.semesterId).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/teacher-student-counselling/semester/teacherId', {
      headers: this.appService.getHeadersWithAccessToken(), params: myParams
    })
      .subscribe(res => {
          console.log(res);
          this.studentCounsellingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

}
