import {Component, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {Toast, ToastsManager} from 'ng2-toastr';
import {AppService} from '../appService';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-assign-teacher-monitor-unassigned-student',
  templateUrl: './assign-teacher-monitor-unassigned-student.component.html',
  styleUrls: ['./assign-teacher-monitor-unassigned-student.component.css']
})
export class AssignTeacherMonitorUnassignedStudentComponent implements OnInit {
  teacherIdOrNameFilter: any = '';
  studentIdOrNameFilter: any = '';

  employeeIdorNameToSearchTeacher = '';
  studentIdToSearchStudent = '';
  selectedStudentIdToSearchMentor = '';

  teacherListToSelect: any = [];
  studentListToSelect: any = [];
  selectedTeacher: any = {};
  // selectedStudentList: any = [];
  mentorListForSelectedStudent: any = [];

  // teacherIdAndNameToSearchAlreadyAssignedTeacherStudentList= '';
  // studentIdAndNameToSearchAlreadyAssignedTeacherStudentList= '';
  // alreadyAssignedTeacherStudentList: any= [];
  assignStudentListForSelectedTeacher: any = [];

  constructor(private appService: AppService, public dialog: MatDialog,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
  }


  getTeacherListToSelectTeacher() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchTeacher);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorUnassignedStudent/employeeList', {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          this.teacherListToSelect = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  getStudentListToSelectStudent() {
    this.studentListToSelect = [];
    if (this.studentIdToSearchStudent.length >= 6) {
      const myParams = new HttpParams().append('studentId', this.studentIdToSearchStudent);
      this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorUnassignedStudent/unAssignStudentList',
        {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
        .subscribe(res => {
            // console.log(res);
            this.studentListToSelect = res;
          },
          msg => {
            console.error('error');
          }
        );
    } else {
      //console.log("less then 6");

      this.toastr.warning('Input at least 6 character. first two part of student id like 181-15', 'Note:', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
        .then((toast: Toast) => {
          setTimeout(() => {
            this.toastr.dismissToast(toast);
          }, 5000);
        });
    }
  }


  selectTeacher(t) {
    this.selectedTeacher = t;
    setTimeout(() => {
      if (Object.keys(this.selectedTeacher).length === 0) {
        // Object is empty
        // console.log('empty');
      } else {
        // Object is NOT empty
        // console.log(this.selectedTeacher['EMPLOYEE_ID']);
        // console.log('not empty');
        this.getStudentListForTeacher(this.selectedTeacher['EMPLOYEE_ID']);
      }
    }, 200);

  }


  getStudentListForTeacher(employeeId) {
    const myParams = new HttpParams().append('employeeId', employeeId);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorUnassignedStudent/assignStudentList', {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          // console.log(res);
          this.assignStudentListForSelectedTeacher = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  assignTeacherToMonitorStudent(student) {
    this.httpClient.post(environment.apiUrl + 'assignTeacherMonitorUnassignedStudent/assignTeacherToStudent',
      {teacherId: this.selectedTeacher.EMPLOYEE_ID, studentId: student.STUDENT_ID},
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {
                setTimeout(() => {
                  this.toastr.dismissToast(toast);
                }, 2500);
                this.getStudentListForTeacher(this.selectedTeacher['EMPLOYEE_ID']);
                this.getStudentListToSelectStudent();
              });
          } else {
            this.toastr.error(res['message'], 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {
                setTimeout(() => {
                  this.toastr.dismissToast(toast);
                }, 2500);
              });
          }
        },
        msg => {
          console.error('error');
        }
      );
  }


  deleteAssignedTeacherStudent(id, studentId) {
    const myParams = new HttpParams().append('id', id).append('studentId', studentId).append('teacherId', this.selectedTeacher.EMPLOYEE_ID);
    this.httpClient.delete(environment.apiUrl + 'assignTeacherMonitorUnassignedStudent/deleteAssignedTeacherStudent',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {
                setTimeout(() => {
                  this.toastr.dismissToast(toast);
                }, 2500);
                this.getStudentListForTeacher(this.selectedTeacher['EMPLOYEE_ID']);
              });
          } else {
            this.toastr.error(res['message'], 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {
                setTimeout(() => {
                  this.toastr.dismissToast(toast);
                }, 2500);
              });
          }
        },
        msg => {
          console.error('error');
        }
      );
  }


  getMentorListForSelectedStudent() {
    const myParams = new HttpParams().append('studentId', this.selectedStudentIdToSearchMentor);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorUnassignedStudent/mentorListForSelectedStudent',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          this.mentorListForSelectedStudent = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


}

