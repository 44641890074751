import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Params, Router} from '@angular/router';
import {Toast, ToastsManager} from 'ng2-toastr/ng2-toastr';
import { environment } from '../../environments/environment';
import {AppService} from '../appService';

@Component({
  selector: 'app-assign-teacher-monitor-student',
  templateUrl: './assign-teacher-monitor-student.component.html',
  styleUrls: ['./assign-teacher-monitor-student.component.css']
})
export class AssignTeacherMonitorStudentComponent implements OnInit {

  employeeIdorNameToSearchTeacher: any = '';
  studentIdToSearchStudent: any = '';
  teacherIdOrNameFilter: any = '';

  teacherList: any = [];
  studentList: any = [];
  selectedTeacher: any = {};
  selectedStudentList: any = [];

  teacherIdAndNameToSearchAlreadyAssignedTeacherStudentList: any = '';
  studentIdAndNameToSearchAlreadyAssignedTeacherStudentList: any = '';
  alreadyAssignedTeacherStudentList: any= [];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }
  ngOnInit() {
    this.appService.hasAccessToken();
  }

  getTeacherListToSelectTeacher() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchTeacher);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorStudent/employeeList',  {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.teacherList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getStudentListToSelectStudent() {
    const myParams = new HttpParams().append('studentId', this.studentIdToSearchStudent);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorStudent/studentList',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.studentList = res;
        },
        msg => {         console.error('error');    }
      );
  }

  selectTeacher(t) {
    this.selectedTeacher = t;
  }

  selectStudent(t) {
    this.selectedStudentList.push(t);
  }

  clearStudentList() {
    this.selectedStudentList = [];
  }

  clearSingleSelectedStudent(selectedStudent, index) {
    this.selectedStudentList.splice(index, 1);
  }



  assignTeacherToMonitorStudent() {
    const tempArr = [];
    for (const te of this.selectedStudentList)  {  tempArr.push(te.STUDENT_ID);   }
    this.httpClient.post(environment.apiUrl + 'assignTeacherMonitorStudent/assignTeacherToStudent',
      {teacherId: this.selectedTeacher.EMPLOYEE_ID, studentIdList: tempArr},
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(  res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          } else {
            this.toastr.success(res['message'], 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          }
        },
        msg => {    console.error('error');    }
      );
  }

  alreadyAssignedTeacherStudentListSearchByTeacher() {
    const myParams = new HttpParams().append('teacherId', this.teacherIdAndNameToSearchAlreadyAssignedTeacherStudentList);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorStudent/teacherStudentListByTeacherId',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedTeacherStudentList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  alreadyAssignedTeacherStudentListSearchByStudent() {
    const myParams = new HttpParams().append('studentId', this.studentIdAndNameToSearchAlreadyAssignedTeacherStudentList);
    this.httpClient.get(environment.apiUrl + 'assignTeacherMonitorStudent/teacherStudentListByStudentId',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedTeacherStudentList = res;
        },
        msg => {    console.error('error');    }
      );
  }
  deleteAssignedTeacherStudent(id) {
    const myParams = new HttpParams().append('id', id);
    this.httpClient.delete(environment.apiUrl + 'assignTeacherMonitorStudent/deleteAssignedTeacherStudent',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          } else {
            this.toastr.success(res['message'], 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          }
        },
        msg => {    console.error('error');    }
      );
  }


}



