import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable()
export class AppService {

  appName= 'mentoring system';
  tokenKeyName= 'mentoringToken';
  headers= null;

  constructor(private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private httpClient: HttpClient,
              private router: Router) {
  }

  getHeadersWithoutAccessToken(): HttpHeaders {
     this.headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json, text/plain, */*')
      .append('Access-Control-Allow-Headers', '*');
     return this.headers;
  }

  getHeadersWithAccessToken(): HttpHeaders {
     this.headers = new HttpHeaders()
      .append('accesstoken', this.getAccessToken())
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json, text/plain, */*')
      .append('Access-Control-Allow-Headers', '*');
     return this.headers;
  }
  hasAccessToken() {
    const token = this.localStorage.retrieve(this.tokenKeyName);
    if (token == null) {
      this.router.navigate(['/login']);
    }
  }


  getAccessToken(): any {
    const token = this.localStorage.retrieve(this.tokenKeyName);
    if (token == null) {
      return null;
    }
    return token.accessToken;
  }

  getAccessTokenInfo(): any {
    const token = this.localStorage.retrieve(this.tokenKeyName);
    if (token == null) {
      return null;
    }
    return token;
  }

  saveAccessToken(res) {
    this.localStorage.store(this.tokenKeyName, res);
  }
  deleteAccessToken() {
    this.localStorage.clear(this.tokenKeyName);
  }


  hasAccess(role: string) {
    const token = this.localStorage.retrieve(this.tokenKeyName);
    if (token == null) {
      return false;
    }
    // console.log(role);
    // console.log(token);
    // console.log(token.commaSeparatedRoles);
    const roles = token.commaSeparatedRoles.split(',');
    for (const r of roles) {
      if (r === role) {  return true; }
    }
    return false;
  }

  isLoggedin() {
    const token = this.localStorage.retrieve(this.tokenKeyName);
    if (token == null) {
      return false;
    } else {
      return true;
    }
  }

}
