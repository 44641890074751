import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppService} from '../../appService';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Toast, ToastsManager} from 'ng2-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-single-student-mentoring-by-head',
  templateUrl: './single-student-mentoring-by-head.component.html',
  styleUrls: ['./single-student-mentoring-by-head.component.css']
})
export class SingleStudentMentoringByHeadComponent implements OnInit {
  summaryResultCourseCodeOrTitleFilter: any = '';
  detailsResultCourseCodeOrTitleFilter: any = '';
  attendanceCourseCodeOrTitleFilter: any = '';

  teacherId: any = '';
  attendanceList: any = [];
  studentId: any = '';
  singleStudentDetails: any = {};
  semesterList: any = [];
  semesterIdToSearchSingleStudentResult = '';
  semesterIdToSearchSingleStudentAttendance = '';

  brifDescription: any = '';
  personalInterest: any = '';
  initialProblem: any = '';
  strangthOfStudent: any = '';
  weaknessOfStudent: any = '';
  additionalInfoOfStudent: any = '';

  singleStudentResultSummery: any = {};
  singleStudentPaymentLedgerSummery: any = {};
  semesterIdToSearchSingleStudentResultDetails = '';
  singleStudentResultDetailsList: any = [];
  singleStudentResultList: any = [];
  singleStudentSgpaList: any = [];
  singleStudentPaymentHistory: any = {};
  singleStudentCounsellingHistory: any = {};
  photograph: any = '';

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe((params: Params) => {
      this.studentId = params['studentId'];
      this.teacherId = params['teacherId'];
    });

    // console.log(this.studentId);
    // console.log(this.teacherId);

    this.getSingleStudentDetails();
    this.getSingleStudentObservation();
    this.getSemesterList();
    this.getSingleStudentResultSummery();
    this.getSingleStudentPaymentLedgerSummery();
    this.getSingleStudentSgpaList();
    this.getSingleStudentPaymentHistory();

  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'headAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentObservation() {
    const myParams = new HttpParams().append('studentId', this.studentId).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentObservation', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);

          this.brifDescription = res['brifDescription'];
          this.personalInterest = res['personalInterest'];
          this.initialProblem = res['initialProblem'];
          this.strangthOfStudent = res['strangthOfStudent'];
          this.weaknessOfStudent = res['weaknessOfStudent'];
          this.additionalInfoOfStudent = res['additionalInfoOfStudent'];
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentPhotograph(personId) {
    // console.log('personId ', personId);

    const myParams = new HttpParams().append('personId', personId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentPhotograph', {
      headers: this.appService.getHeadersWithAccessToken(),
      responseType: 'text',
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.photograph = 'data:image/jpeg;base64,' + res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultSummery() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentResultSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentSgpaList() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentSgpaList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentSgpaList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPaymentLedgerSummery() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentPaymentLedgerSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentLedgerSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentDetails() {
    const myParams = new HttpParams().append('studentId', this.studentId).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'headAccess/v2/singleStudentDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentDetails = res;
          // console.log(res);
          if ( res !== null || res !== undefined) {
            this.getSingleStudentPhotograph(this.singleStudentDetails['photo'].personId);
          }

        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentPaymentHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentRecentPayment', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentHistory = res;
          this.getSingleStudentCounsellingHistory();
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentCounsellingHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultDetailsList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResultDetails).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentResultDetailsList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultDetailsList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResult).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentResultList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }



  getAttendanceList() {
    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentAttendance).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'headAccess/singleStudentAttendanceList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.attendanceList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getPresentAttendanceTotal(attendance) {
    let count = 0;
    const objectKey = Object.keys(attendance);
    for (let i = 0; i < objectKey.length; i++) {
      if (objectKey[i].startsWith('A')) {
        const paramValue = attendance[objectKey[i]];
        if (paramValue) { count += 1; }
      }
    }
    return count;
  }

}
