import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

// for solving page not found after refresh
/*import {provide} from 'angular2/angular2';
import {
  ROUTER_PROVIDERS,
  LocationStrategy,
  HashLocationStrategy
} from 'angular2/router';*/

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'hammerjs';

import { FlexLayoutModule } from '@angular/flex-layout';
import {Ng2Webstorage} from 'ngx-webstorage';
import {ToastModule, ToastOptions, ToastsManager} from 'ng2-toastr/ng2-toastr';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { appRoutes } from './app-routing.module';

// angualr material module
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';

import {IdAndNameFilterPipe} from './idAndName-filter.pipe';

import { AppComponent } from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SingleStudentMentoringComponent} from './teacher-access/single-student-mentoring/single-student-mentoring.component';

import { DeanAccessComponent } from './dean-access/dean-access.component';

import { LoginComponent } from './login/login.component';
import { AssignDeanMonitorHeadComponent } from './assign-dean-monitor-head/assign-dean-monitor-head.component';
import { AssignAssociateHeadMonitorTeacherComponent } from './assign-associate-head-monitor-teacher/assign-associate-head-monitor-teacher.component';
import { AssignDeanMonitorAssociateHeadComponent } from './assign-dean-monitor-associate-head/assign-dean-monitor-associate-head.component';

import { AssignTeacherMonitorStudentComponent } from './assign-teacher-monitor-student/assign-teacher-monitor-student.component';

import { TeacherAccessComponent } from './teacher-access/teacher-access.component';
import { AssociateHeadAccessComponent } from './associate-head-access/associate-head-access.component';
import { MeetingWithGuardianComponent } from './teacher-access/single-student-mentoring/meeting-with-guardian/meeting-with-guardian.component';
import { MeetingWithStudentComponent } from './teacher-access/single-student-mentoring/meeting-with-student/meeting-with-student.component';
import { AssignHeadMonitorTeacherComponent } from './assign-head-monitor-teacher/assign-head-monitor-teacher.component';
import { HeadAccessComponent } from './head-access/head-access.component';

import {AppService} from './appService';
import { IndexComponent } from './index/index.component';
import { MeetingWithCourseTeacherComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/meeting-with-course-teacher.component';
import { SingleStudentMentoringByHeadComponent } from './head-access/single-student-mentoring-by-head/single-student-mentoring-by-head.component';
import { MeetingWithStudentByHeadComponent } from './head-access/single-student-mentoring-by-head/meeting-with-student-by-head/meeting-with-student-by-head.component';
import {AssignTeacherMonitorUnassignedStudentComponent} from './assign-teacher-monitor-unassigned-student/assign-teacher-monitor-unassigned-student.component';
import { AssignTeacherMonitorAssignedStudentComponent } from './assign-teacher-monitor-assigned-student/assign-teacher-monitor-assigned-student.component';
import { RegisteredStudentComponent } from './teacher-access/registered-student/registered-student.component';
import { RegisteredStudentByHeadComponent } from './head-access/registered-student-by-head/registered-student-by-head.component';
import { RegisteredStudentByAssociateheadComponent } from './associate-head-access/registered-student-by-associatehead/registered-student-by-associatehead.component';
import { SingleStudentMentoringByAssociateheadComponent } from './associate-head-access/single-student-mentoring-by-associatehead/single-student-mentoring-by-associatehead.component';
import { MeetingWithGuardianByHeadComponent } from './head-access/single-student-mentoring-by-head/meeting-with-guardian-by-head/meeting-with-guardian-by-head.component';
import { MeetingWithCourseTeacherByHeadComponent } from './head-access/single-student-mentoring-by-head/meeting-with-course-teacher-by-head/meeting-with-course-teacher-by-head.component';
import { MeetingWithStudentByAssociateheadComponent } from './associate-head-access/single-student-mentoring-by-associatehead/meeting-with-student-by-associatehead/meeting-with-student-by-associatehead.component';
import { MeetingWithGuardianByAssociateheadComponent } from './associate-head-access/single-student-mentoring-by-associatehead/meeting-with-guardian-by-associatehead/meeting-with-guardian-by-associatehead.component';
import { MeetingWithCourseTeacherByAssociateheadComponent } from './associate-head-access/single-student-mentoring-by-associatehead/meeting-with-course-teacher-by-associatehead/meeting-with-course-teacher-by-associatehead.component';
import { TeacherAccessByDeanComponent } from './dean-access/teacher-access-by-dean/teacher-access-by-dean.component';
import { RegisteredStudentByDeanComponent } from './dean-access/teacher-access-by-dean/registered-student-by-dean/registered-student-by-dean.component';
import { SingleStudentMentoringByDeanComponent } from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/single-student-mentoring-by-dean.component';
import { MeetingWithStudentByDeanComponent } from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/meeting-with-student-by-dean/meeting-with-student-by-dean.component';
import { MeetingWithGuardianByDeanComponent } from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/meeting-with-guardian-by-dean/meeting-with-guardian-by-dean.component';
import { MeetingWithCourseTeacherByDeanComponent } from './dean-access/teacher-access-by-dean/single-student-mentoring-by-dean/meeting-with-course-teacher-by-dean/meeting-with-course-teacher-by-dean.component';
import { FooterComponent } from './footer/footer.component';
import { PanelAccessComponent } from './panel-access/panel-access.component';
import { TeacherAccessByPanelComponent } from './panel-access/teacher-access-by-panel/teacher-access-by-panel.component';
import { RegisteredStudentByPanelComponent } from './panel-access/teacher-access-by-panel/registered-student-by-panel/registered-student-by-panel.component';
import { SingleStudentMentoringByPanelComponent } from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/single-student-mentoring-by-panel.component';
import { MeetingWithStudentByPanelComponent } from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/meeting-with-student-by-panel/meeting-with-student-by-panel.component';
import { MeetingWithGuardianByPanelComponent } from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/meeting-with-guardian-by-panel/meeting-with-guardian-by-panel.component';
import { MeetingWithCourseTeacherByPanelComponent } from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/meeting-with-course-teacher-by-panel/meeting-with-course-teacher-by-panel.component';
import { MeetingWithStudentSaveDialogComponent } from './teacher-access/single-student-mentoring/meeting-with-student/meeting-with-student-save-dialog/meeting-with-student-save-dialog.component';
import { MeetingWithGuardianSaveDialogComponent } from './teacher-access/single-student-mentoring/meeting-with-guardian/meeting-with-guardian-save-dialog/meeting-with-guardian-save-dialog.component';
import { StudentCounsellingComponent } from './student-counselling/student-counselling.component';
import { ConfirmSaveComponent } from './confirm-dialog/confirm-save/confirm-save.component';
import { ConfirmUpdateComponent } from './confirm-dialog/confirm-update/confirm-update.component';
import { ConfirmDeleteComponent } from './confirm-dialog/confirm-delete/confirm-delete.component';
import { CounsellingRequestToMentorComponent } from './student-counselling/counselling-request-to-mentor/counselling-request-to-mentor.component';
import { CounsellingRequestFromMentorComponent } from './student-counselling/counselling-request-from-mentor/counselling-request-from-mentor.component';
import { CounsellingRequestFromMentorDialogComponent } from './student-counselling/counselling-request-from-mentor/counselling-request-from-mentor-dialog/counselling-request-from-mentor-dialog.component';
import { StudentCounsellingShowFromMentorComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/student-counselling-show-from-mentor/student-counselling-show-from-mentor.component';
import { CounsellingRequestFromCourseTeacherComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/counselling-request-from-course-teacher/counselling-request-from-course-teacher.component';
import { CrfctDialogComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/counselling-request-from-course-teacher/crfct-dialog/crfct-dialog.component';
import { CcInstructionDialogComponent } from './student-counselling/cc-instruction-dialog/cc-instruction-dialog.component';
import { CrfmInstructionDialogComponent } from './student-counselling/counselling-request-from-mentor/crfm-instruction-dialog/crfm-instruction-dialog.component';
import { CrtmInstructionDialogComponent } from './student-counselling/counselling-request-to-mentor/crtm-instruction-dialog/crtm-instruction-dialog.component';
import { TaInstructionDialogComponent } from './teacher-access/ta-instruction-dialog/ta-instruction-dialog.component';
import { RequestFromCourseTeacherByPanelComponent } from './panel-access/teacher-access-by-panel/single-student-mentoring-by-panel/request-from-course-teacher-by-panel/request-from-course-teacher-by-panel.component';
import { MwctInstructionDialogComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/mwct-instruction-dialog/mwct-instruction-dialog.component';
import { ScsfmInstructionDialogComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/student-counselling-show-from-mentor/scsfm-instruction-dialog/scsfm-instruction-dialog.component';
import { CrfctInstructionDialogComponent } from './teacher-access/single-student-mentoring/meeting-with-course-teacher/counselling-request-from-course-teacher/crfct-instruction-dialog/crfct-instruction-dialog.component';
import { AdvisingTargetSettingAndKpiByTeacherComponent } from './teacher-access/advising-target-setting-and-kpi-by-teacher/advising-target-setting-and-kpi-by-teacher.component';
import { AdvisingTargetSettingAndKpiByHeadComponent } from './head-access/advising-target-setting-and-kpi-by-head/advising-target-setting-and-kpi-by-head.component';
import { AdvisingTargetSettingAndKpiByDeanComponent } from './dean-access/advising-target-setting-and-kpi-by-dean/advising-target-setting-and-kpi-by-dean.component';
import { AllStudentCounsellingRequestFromCourseTeacherComponent } from './teacher-access/all-student-counselling-request-from-course-teacher/all-student-counselling-request-from-course-teacher.component';
import { AscrfctDialogComponent } from './teacher-access/all-student-counselling-request-from-course-teacher/ascrfct-dialog/ascrfct-dialog.component';
import { AllStudentNextMeetingComponent } from './teacher-access/all-student-next-meeting/all-student-next-meeting.component';
import { AdvisingTargetSettingAndKpiByAssociateHeadComponent } from './associate-head-access/advising-target-setting-and-kpi-by-associate-head/advising-target-setting-and-kpi-by-associate-head.component';
import { SuperAccessComponent } from './super-access/super-access.component';
import {DeveloperComponent} from './developer/developer.component';
import {AgGridModule} from 'ag-grid-angular';
import { CounsellingListByTeacherComponent } from './panel-access/counselling-list-by-teacher/counselling-list-by-teacher.component';
import { StudentLookupComponent } from './student-lookup/student-lookup.component';
import { SemesterResultComponent } from './student-lookup/semester-result/semester-result.component';
import { ClassAttendanceComponent } from './student-lookup/class-attendance/class-attendance.component';
import { CourseAttendanceDialogComponent } from './student-lookup/class-attendance/course-attendance-dialog/course-attendance-dialog.component';
import { CounsellingComponent } from './student-lookup/counselling/counselling.component';
import { TeacherLookupComponent } from './teacher-lookup/teacher-lookup.component';
import { MentoringRptComponent } from './teacher-lookup/mentoring-rpt/mentoring-rpt.component';
import {MentorAssessmentRptComponent} from './mentor-assessment/mentor-assessment-rpt/mentor-assessment-rpt.component';
import {MentorAssessmentComponent} from './mentor-assessment/mentor-assessment.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DatePipe} from '@angular/common';
import { ProctorialRequestToMentorComponent } from './student-counselling/protorial-request-to-mentor/proctorial-request-to-mentor.component';
import { ProctorialRequestFromMentorComponent } from './student-counselling/proctorial-request-from-mentor/proctorial-request-from-mentor.component';
import { AllStudentCounsellingRequestFromProctorComponent } from './teacher-access/all-student-counselling-request-from-proctor/all-student-counselling-request-from-proctor.component';
import { ProctorCounsellingRequestDialogComponent } from './teacher-access/all-student-counselling-request-from-proctor/proctor-counselling-request-dialog/proctor-counselling-request-dialog.component';
import { MentoringReportComponent } from './mentoring-report/mentoring-report.component';
import { MentorNotAssignedComponent } from './mentoring-report/mentor-not-assigned/mentor-not-assigned.component';
import { StudentAttendanceAndCgpaComponent } from './mentoring-report/student-attendance-and-cgpa/student-attendance-and-cgpa.component';
import { DistrictWiseStudentComponent } from './mentoring-report/district-wise-student/district-wise-student.component';
import { StudentNotRegistredComponent } from './mentoring-report/student-not-registred/student-not-registred.component';
import { MentoringTopicBasedReportComponent } from './mentoring-report/mentoring-topic-based-report/mentoring-topic-based-report.component';
import {ProctorAccessComponent} from './proctor-access/proctor-access/proctor-access.component';
import { ExpireStudentsComponent } from './mentoring-report/expire-students/expire-students.component';
import {MentorMeetingWithGaurdianComponent} from './mentoring-report/mentor-meeting-with-gaurdian/mentor-meeting-with-gaurdian.component';
import { MentorMeetingWithCourseTeacherComponent } from './mentoring-report/mentor-meeting-with-course-teacher/mentor-meeting-with-course-teacher.component';



// const busyConfig: BusyConfig = {message: 'Please Wait...', template: '', backdrop: false, delay: 0, minDuration: 0, wrapperClass: '' };

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SingleStudentMentoringComponent,
    MeetingWithStudentComponent,
    DeanAccessComponent,
    LoginComponent,
    AssignDeanMonitorHeadComponent,
    AssignAssociateHeadMonitorTeacherComponent,
    AssignDeanMonitorAssociateHeadComponent,
    AssignTeacherMonitorStudentComponent,
    TeacherAccessComponent,
    AssociateHeadAccessComponent,
    MeetingWithGuardianComponent,
    AssignHeadMonitorTeacherComponent,
    HeadAccessComponent,
    IndexComponent,
    MeetingWithCourseTeacherComponent,
    SingleStudentMentoringByHeadComponent,
    MeetingWithStudentByHeadComponent,
    AssignTeacherMonitorUnassignedStudentComponent,
    AssignTeacherMonitorAssignedStudentComponent,
    RegisteredStudentComponent,
    RegisteredStudentByHeadComponent,
    RegisteredStudentByAssociateheadComponent,
    SingleStudentMentoringByAssociateheadComponent,
    MeetingWithGuardianByHeadComponent,
    MeetingWithCourseTeacherByHeadComponent,
    MeetingWithStudentByAssociateheadComponent,
    MeetingWithGuardianByAssociateheadComponent,
    MeetingWithCourseTeacherByAssociateheadComponent,
    TeacherAccessByDeanComponent,
    RegisteredStudentByDeanComponent,
    SingleStudentMentoringByDeanComponent,
    MeetingWithStudentByDeanComponent,
    MeetingWithGuardianByDeanComponent,
    MeetingWithCourseTeacherByDeanComponent,
    FooterComponent,
    PanelAccessComponent,
    TeacherAccessByPanelComponent,
    RegisteredStudentByPanelComponent,
    SingleStudentMentoringByPanelComponent,
    MeetingWithStudentByPanelComponent,
    MeetingWithGuardianByPanelComponent,
    MeetingWithCourseTeacherByPanelComponent,
    IdAndNameFilterPipe,
    MeetingWithStudentSaveDialogComponent,
    MeetingWithGuardianSaveDialogComponent,
    StudentCounsellingComponent,
    ConfirmSaveComponent,
    ConfirmUpdateComponent,
    ConfirmDeleteComponent,
    CounsellingRequestToMentorComponent,
    CounsellingRequestFromMentorComponent,
    CounsellingRequestFromMentorDialogComponent,
    StudentCounsellingShowFromMentorComponent,
    CounsellingRequestFromCourseTeacherComponent,
    CrfctDialogComponent,
    CcInstructionDialogComponent,
    CrfmInstructionDialogComponent,
    CrtmInstructionDialogComponent,
    TaInstructionDialogComponent,
    RequestFromCourseTeacherByPanelComponent,
    MwctInstructionDialogComponent,
    ScsfmInstructionDialogComponent,
    CrfctInstructionDialogComponent,
    AdvisingTargetSettingAndKpiByTeacherComponent,
    AdvisingTargetSettingAndKpiByHeadComponent,
    AdvisingTargetSettingAndKpiByDeanComponent,
    AllStudentCounsellingRequestFromCourseTeacherComponent,
    AscrfctDialogComponent,
    AllStudentNextMeetingComponent,
    AdvisingTargetSettingAndKpiByAssociateHeadComponent,
    SuperAccessComponent,
    DeveloperComponent,
    CounsellingListByTeacherComponent,
    StudentLookupComponent,
    SemesterResultComponent,
    ClassAttendanceComponent,
    CourseAttendanceDialogComponent,
    CounsellingComponent,
    TeacherLookupComponent,
    MentoringRptComponent,
    MentorAssessmentComponent,
    MentorAssessmentRptComponent,
    ProctorialRequestToMentorComponent,
    ProctorialRequestFromMentorComponent,
    AllStudentCounsellingRequestFromProctorComponent,
    ProctorCounsellingRequestDialogComponent,
    MentoringReportComponent,
    MentorNotAssignedComponent,
    StudentAttendanceAndCgpaComponent,
    DistrictWiseStudentComponent,
    CrfmInstructionDialogComponent,
    StudentNotRegistredComponent,
    MentoringTopicBasedReportComponent,
    ProctorAccessComponent,
    ExpireStudentsComponent,
    MentorMeetingWithGaurdianComponent,
    MentorMeetingWithCourseTeacherComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    Ng2Webstorage,
    ToastModule,
    AngularFontAwesomeModule,
    AgGridModule.withComponents([]),
// angular material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxChartsModule,
  ],
  providers: [AppService, ToastsManager, ToastOptions, DatePipe],
  bootstrap: [AppComponent],
  // tslint:disable-next-line:max-line-length
  entryComponents: [ MeetingWithStudentSaveDialogComponent, MeetingWithGuardianSaveDialogComponent, CounsellingRequestFromMentorDialogComponent, ConfirmSaveComponent, ConfirmUpdateComponent, ConfirmDeleteComponent, CcInstructionDialogComponent, CrfctDialogComponent, CrfmInstructionDialogComponent, CrtmInstructionDialogComponent, TaInstructionDialogComponent, MwctInstructionDialogComponent, ScsfmInstructionDialogComponent, CrfctInstructionDialogComponent, AscrfctDialogComponent, CourseAttendanceDialogComponent, MentoringRptComponent, MentorAssessmentRptComponent, ProctorCounsellingRequestDialogComponent ]
  // for solving page not found after refresh
  /*bootstrap: [AppComponent,
    ROUTER_PROVIDERS,
    provide(LocationStrategy, {useClass: HashLocationStrategy})
  ]*/
})
export class AppModule { }
