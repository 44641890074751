import { Component, OnInit } from '@angular/core';
import {AppService} from '../appService';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager, Toast } from 'ng2-toastr';
import {environment} from '../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-student-lookup',
  templateUrl: './student-lookup.component.html',
  styleUrls: ['./student-lookup.component.css']
})
export class StudentLookupComponent implements OnInit {
  studentId: any;
  imagePath: any;
  isShowData: boolean;
  studentDetails: any = {};
  singleStudentResultSummery: any = {};
  singleStudentPaymentLedgerSummery: any = {};
  singleStudentSgpaList: any = [];
  brifDescription: any = '';
  personalInterest: any = '';
  initialProblem: any = '';
  strangthOfStudent: any = '';
  weaknessOfStudent: any = '';
  additionalInfoOfStudent: any = '';
  singleStudentPaymentHistory: any = {};
  singleStudentCounsellingHistory: any = {};
  singleStudentProctorialCounsellingHistory: any = {};
  semesterList: any = [];
  semesterIdToSearchSingleStudentResultDetails = '';
  singleStudentResultDetailsList: any = [];
  singleStudentResultList: any = [];
  semesterIdToSearchSingleStudentResult = '';
  semesterIdToSearchSingleStudentAttendance = '';
  attendanceList: any = [];
  summaryResultCourseCodeOrTitleFilter: any = '';
  detailsResultCourseCodeOrTitleFilter: any = '';
  attendanceCourseCodeOrTitleFilter: any = '';
  constructor(private appService: AppService, private _sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (Object.keys(params).length !== 0) {
        this.studentId = params['studentId'];
        this.getStudentInfo();
      }
    });
  /*  if (localStorage.getItem('stdIdForStdLookup')) {
      this.studentId = localStorage.getItem('stdIdForStdLookup');
      this.getStudentInfo();
    }*/
  }

  getStudentInfo() {
    this.isShowData = true;
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/v3/singleStudentDetails',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res['error']);
          if (res !== null) {
            this.toastr.success('Student Details Found!!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }
          this.studentDetails = res;
          this.imagePath = 'data:image/jpeg;base64,' + this.studentDetails.photo.studentPhoto;
          // localStorage.setItem('stdIdForStdLookup', this.studentDetails.studentId);

          this.isShowData = false;
          this.getSingleStudentResultSummery(this.studentId);
          this.getSingleStudentPaymentLedgerSummery(this.studentId);
          this.getSingleStudentSgpaList(this.studentId);
          this.getSingleStudentObservation(this.studentId);
          this.getSingleStudentPaymentHistory(this.studentId);
          this.getSemesterList();
          this.getSingleStudentCounsellingHistory(this.studentId);
          this.getSingleStudentProctorialCounsellingHistory(this.studentId);
        },
        msg => {
        this.toastr.error('Data Not Found');
          this.toastr.error('Data Not Found', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }

  getSingleStudentResultSummery(studentId) {
    const myParams = new HttpParams().append('studentId', studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentResultSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPaymentHistory(studentId) {
    const myParams = new HttpParams().append('studentId', studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentRecentPayment', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentCounsellingHistory(studentId) {
    const myParams = new HttpParams().append('studentId', studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentProctorialCounsellingHistory(studentId) {
    const myParams = new HttpParams().append('studentId', studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentProctorialCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentProctorialCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentPaymentLedgerSummery(studentId) {
    const myParams = new HttpParams().append('studentId', studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentPaymentLedgerSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentLedgerSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentSgpaList(studentId) {
    const myParams = new HttpParams().append('studentId', studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentSgpaList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentSgpaList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentObservation(studentId) {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/v2/singleStudentObservation', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);

          this.brifDescription = res['brifDescription'];
          this.personalInterest = res['personalInterest'];
          this.initialProblem = res['initialProblem'];
          this.strangthOfStudent = res['strangthOfStudent'];
          this.weaknessOfStudent = res['weaknessOfStudent'];
          this.additionalInfoOfStudent = res['additionalInfoOfStudent'];
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentResultDetailsList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResultDetails).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentResultDetailsList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultDetailsList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResult).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentResultList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }



  getAttendanceList() {
    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentAttendance).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentAttendanceList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.attendanceList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getPresentAttendanceTotal(attendance) {
    let count = 0;
    const objectKey = Object.keys(attendance);
    for (let i = 0; i < objectKey.length; i++) {
      if (objectKey[i].startsWith('A')) {
        const paramValue = attendance[objectKey[i]];
        if (paramValue) { count += 1; }
      }
    }
    return count;
  }


  semesterResultUrl() {
    this.router.navigate(['student-lookup/semester-result/' + this.studentId]);
  }
  semesterClassAttendanceUrl() {
    this.router.navigate(['student-lookup/class-attendance/' + this.studentId]);
  }
  counsellingUrl() {
    this.router.navigate(['student-lookup/counselling/' + this.studentId]);
  }


}
