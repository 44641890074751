import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {MatDialog} from '@angular/material';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../appService';
import {ActivatedRoute, Router} from '@angular/router';
import {Toast, ToastsManager} from 'ng2-toastr';
import {CcInstructionDialogComponent} from './cc-instruction-dialog/cc-instruction-dialog.component';
import {ConfirmSaveComponent} from '../confirm-dialog/confirm-save/confirm-save.component';

@Component({
  selector: 'app-student-counselling',
  templateUrl: './student-counselling.component.html',
  styleUrls: ['./student-counselling.component.css']
})
export class StudentCounsellingComponent implements OnInit {

  selectedSemesterForSave: any = '';
  selectedSemesterForSearch: any = '';
  studentId: any = '';
  meeting_reason: any = '';
  counselling_title: any = '';
  counselling_detail: any = '';
  studentIdNameFilter: any = '';

  semesterList: any = [];
  studentCounsellingList: any = [];
  studentDetails: any = null;
  submitData: any = {};

  meetingReasonList: any = [
    {reason: 'Academic Discussion'},
    {reason: 'Personal Dialogue'},
    {reason: 'Career Building'},
    {reason: 'Motivational Talk'},
    {reason: 'Class Monitoring'},
    {reason: 'Registration'},
    {reason: 'Result'},
    {reason: 'Attendance'},
    {reason: 'Payment Due'},
    {reason: 'Extracurricular Activities'}
  ];



  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr); }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterList();
  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'studentCounselling/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getStudentDetails() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'studentCounselling/studentDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams})
      .subscribe(res => {
           //console.log(res);
          this.studentDetails = res;

          if (res != null) {
            this.toastr.success('Valid Student Id.', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000); });
          }
          if (res == null) {
            this.toastr.error('Invalid Student Id!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Logout Failed!', 'Oops!!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }


  clearData () {
    this.selectedSemesterForSave = '';
    this.studentId = '';
    this.studentDetails = null;
    this.meeting_reason = '';
    this.counselling_title = '';
    this.counselling_detail = '';
  }



  saveStudentCounselling() {
    this.submitData = {
      semester_id: this.selectedSemesterForSave,
      student_id: this.studentDetails.student_id,
      meeting_reason: this.meeting_reason,
      counselling_title: this.counselling_title,
      counselling_detail: this.counselling_detail,
    };
    // console.log(this.submitData);

    this.httpClient.post(environment.apiUrl + 'studentCounselling/teacherStudentCounselling', this.submitData, { headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(
        res => {
          // console.log('res' , res);
          if (res['status'] === 'success') {
            this.toastr.success('Counselling Saved Success!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);     this.clearData();    });
          }
          if (res['status'] === 'failed') {
            this.toastr.error('Counselling Saved failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Logout Failed!', 'Oops!!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }


  saveStudentCounsellingWithConfirmation(): void {

    // console.log('opening dialog');

    const dialogRef = this.dialog.open(ConfirmSaveComponent, {
      width: 'auto',  data: {today: new Date()}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined ) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");
        if (result.confirmStatus === 'yes' ) {
          // console.log('save');
          this.saveStudentCounselling();
        }else {
          // console.log("not save");
        }
      }
    });
  }


  getStudentCounsellingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
    this.httpClient.get(environment.apiUrl + 'studentCounselling/teacherStudentCounsellingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('res' , res);
          this.studentCounsellingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }


  openCcInstructionDialog(): void {
    const dialogRef = this.dialog.open(CcInstructionDialogComponent, {
      width: '60%',
      height: 'auto',
      disableClose: false,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }


}
