import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';

import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Toast, ToastsManager} from 'ng2-toastr';



@Component({
  selector: 'app-single-student-mentoring',
  templateUrl: './single-student-mentoring.component.html',
  styleUrls: ['./single-student-mentoring.component.css']
})
export class SingleStudentMentoringComponent implements OnInit {
  summaryResultCourseCodeOrTitleFilter: any = '';
  detailsResultCourseCodeOrTitleFilter: any = '';
  attendanceCourseCodeOrTitleFilter: any = '';

  attendanceList: any = [];
  studentId: any = '';
  singleStudentDetails: any = {};
  singleStudentCourseNeedToRetakeList = [];
  singleStudentResultSummery: any = {};
  singleStudentPaymentHistory: any = {};
  singleStudentCounsellingHistory: any = {};
  singleStudentProctorialCounsellingHistory: any = {};
  singleStudentSgpaList: any = [];
  singleStudentPaymentLedgerSummery: any = {};
  semesterList: any = [];
  semesterIdToSearchSingleStudentResultDetails = '';
  semesterIdToSearchSingleStudentResult = '';
  semesterIdToSearchSingleStudentAttendance = '';
  singleStudentResultDetailsList: any = [];
  singleStudentResultList: any = [];
  photograph: any = '';

  brifDescription: any = '';
  personalInterest: any = '';
  initialProblem: any = '';
  strangthOfStudent: any = '';
  weaknessOfStudent: any = '';
  additionalInfoOfStudent: any = '';

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe((params: Params) => {
      this.studentId = params['studentId'];
    });
    // console.log(this.studentId);
    this.getSingleStudentDetails();
    this.getSingleStudentObservation();
    this.getSemesterList();
    this.getSingleStudentResultSummery();
    this.getSingleStudentPaymentLedgerSummery();
    this.getSingleStudentSgpaList();
    this.getSingleStudentPaymentHistory();
    this.getSingleStudentCounsellingHistory();
    this.getSingleStudentProctorialCounsellingHistory();
  }

  getSingleStudentObservation() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentObservation', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {

          this.brifDescription = res['brifDescription'];
          this.personalInterest = res['personalInterest'];
          this.initialProblem = res['initialProblem'];
          this.strangthOfStudent = res['strangthOfStudent'];
          this.weaknessOfStudent = res['weaknessOfStudent'];
          this.additionalInfoOfStudent = res['additionalInfoOfStudent'];
        },
        msg => {
          console.error('error');
        }
      );
  }

  updateSingleStudentObservation() {
    this.httpClient.put(environment.apiUrl + 'teacherAccess/singleStudentObservation',
      {
        studentId: this.studentId,
        brifDescription: this.brifDescription,
        personalInterest: this.personalInterest,
        initialProblem: this.initialProblem,
        strangthOfStudent: this.strangthOfStudent,
        weaknessOfStudent: this.weaknessOfStudent,
        additionalInfoOfStudent: this.additionalInfoOfStudent
      },
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe( res => {
         // console.log(res);

          if (res['status'] === 'success') {
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error(res['message'], 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);  });
          }
        },
          msg => {
            console.error(`Error: ${msg.status} ${msg.statusText}`);
            this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
              .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
              });
          }
      );

  }


  getSingleStudentPhotograph(personId) {
   // console.log('personId ', personId);

    const myParams = new HttpParams().append('personId', personId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentPhotograph', {
      headers: this.appService.getHeadersWithAccessToken(),
      responseType: 'text',
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.photograph = 'data:image/jpeg;base64,' + res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSingleStudentProctorialCounsellingHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/singleStudentProctorialCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentProctorialCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentResultSummery() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentResultSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultSummery = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPaymentHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentRecentPayment', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentCounsellingHistory() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentCounselling', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentCounsellingHistory = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentSgpaList() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentSgpaList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentSgpaList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentPaymentLedgerSummery() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentPaymentLedgerSummery', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentPaymentLedgerSummery = res;

          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentDetails() {
    const myParams = new HttpParams().append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentDetails = res;
          //console.log(this.singleStudentDetails);
          this.photograph = 'data:image/jpeg;base64,' + this.singleStudentDetails.photo.studentPhoto;
          var list = res['dropOutCause']['dropoutCause'][res['dropOutCause']['dropoutCause'].length-1].split(",");
          for (var index in list){

            var st1 = list[index].replace("'","");
            var st2 = st1.replace("]}","");
            var st3 = st2.replace("{courses_need_to_retake':['","");
            var st4 = st3.replace("'","");
            this.singleStudentCourseNeedToRetakeList.push(st4);
          }

          console.log(this.singleStudentCourseNeedToRetakeList);
          // console.log(res);

          if ( res !== null || res !== undefined) {
            this.getSingleStudentPhotograph(res['PERSON_ID']);
          }

        },
        msg => {
          console.error('error');
        }
      );
  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  getSingleStudentResultDetailsList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResultDetails).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentResultDetailsList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultDetailsList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getSingleStudentResultList() {

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentResult).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentResultList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.singleStudentResultList = res;
           // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }


  getAttendanceList() {

    // console.log(this.semesterIdToSearchSingleStudentAttendance);

    const myParams = new HttpParams().append('semesterId', this.semesterIdToSearchSingleStudentAttendance).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/singleStudentAttendanceList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          this.attendanceList = res;
          // console.log(res);
        },
        msg => {
          console.error('error');
        }
      );
  }

  getPresentAttendanceTotal(attendance) {
    let count = 0;
    const objectKey = Object.keys(attendance);
    for (let i = 0; i < objectKey.length; i++) {
      if (objectKey[i].startsWith('A')) {
        const paramValue = attendance[objectKey[i]];
        if (paramValue) { count += 1; }
      }
    }
    return count;
  }
}

