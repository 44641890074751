import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AppService} from '../appService';
import {Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-head-access',
  templateUrl: './head-access.component.html',
  styleUrls: ['./head-access.component.css']
})
export class HeadAccessComponent implements OnInit {
  teacherIdOrNameFilter: any = '';
  mentoringStudentIdOrNameFilter: any = '';
  passoutOrCompletedStudentIdOrNameFilter: any = '';

  employeeList: any = [] ;
  studentList: any = [] ;
  selectedTeacherId: any = '';
  selectedTeacherName: any = '';
  selectedStudent: any = {} ;

  studentListForMentoring: any = [];
  studentListForComplete: any = [];


  view: any[] = [700, 400];

  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  legendPosition = 'below';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  // pieData = [
  //   {
  //     'name': 'Germany',
  //     'value': 8940000
  //   },
  //   {
  //     'name': 'USA',
  //     'value': 5000000
  //   },
  //   {
  //     'name': 'France',
  //     'value': 7200000
  //   },
  //   {
  //     'name': 'UK',
  //     'value': 6200000
  //   }
  // ];

  pieData: any;

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.getEmployeeListForHead();
  }

  getEmployeeListForHead() {
    this.httpClient.get(environment.apiUrl + 'headAccess/employeeList',  {headers: this.appService.getHeadersWithAccessToken() })
      .subscribe(  res => {
          // console.log(res);
          this.employeeList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getStudentListForTeacher(teacher_id, teacher_first_name) {

    this.selectedTeacherId = teacher_id;
    this.selectedTeacherName = teacher_first_name;

    this.getStudentListForMentoring(teacher_id);
    this.getStudentListForComplete(teacher_id);

  /* const myParams = new HttpParams().append('teacherId', teacher_id);
   this.httpClient.get(environment.apiUrl + 'headAccess/studentListByTeacher',
      {headers: this.appService.getHeadersWithAccessToken() , params: myParams})
      .subscribe(  res => {       this.studentList = res;   },
        msg => {    console.error('error');    }
      );*/
  }


  getStudentListForMentoring(teacher_id) {
    const myParams = new HttpParams().append('teacherId', teacher_id);
    this.httpClient.get(environment.apiUrl + 'headAccess/studentListForMentoring',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForMentoring = res['studentList'];
          this.pieData = res['chart'];
        },
        msg => {  console.error('error');    }
      );
  }


  getStudentListForComplete(teacher_id) {
    const myParams = new HttpParams().append('teacherId', teacher_id);
    this.httpClient.get(environment.apiUrl + 'headAccess/studentListForComplete',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForComplete = res;
        },
        msg => {         console.error('error');    }
      );
  }


  goToHeadAccessSingleStudentMentoringLink(studentId) {
    this.router.navigate(['head-access/' + studentId + '/' + this.selectedTeacherId + '/single-student-mentoring']);
  }


  onSelect(event: any) {

  }

  onActivate(any: any) {

  }

  onDeactivate(any: any) {

  }
}
