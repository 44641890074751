import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import * as XLSX from 'xlsx';
import {GridApi} from 'ag-grid-community';

@Component({
  selector: 'app-registered-student-by-panel',
  templateUrl: './registered-student-by-panel.component.html',
  styleUrls: ['./registered-student-by-panel.component.css']
})
export class RegisteredStudentByPanelComponent implements OnInit {
  registeredStudentIdOrNameFilter: any = '';
  unRegisteredStudentIdOrNameFilter: any = '';

  headOrAssociateHeadId: any = '';
  designation: any = '';
  isShowData= false;
  teacherId: any = '';
  semesterList: any = [];
  selectedSemesterForSearch: any = '';
  registeredStudentList: any = [];
  unRegisteredStudentList: any = [];
  isRegisteredChecked = false;
  isUnregisteredChecked = false;


  registeredStdListColumn = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      suppressFilter: true,
      // template: `<a style="background: #006DD2; color: white; border: none; padding: 5px 5px;" target="_blank" data-action-type="viewMentoringStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></a>`
      template: `<button style="background: #006DD2; color: white; border: none; padding: 5px 5px;" data-action-type="viewMentoringStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></button>`
    },
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 210, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'DUE', field: 'dueAmount', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Proctorial Issue', field: 'proctorial', width: 100, sortable: true, unSortIcon: true},
    {headerName: 'Course Taken', field: 'CourseTaken', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'Expire', field: 'EXPIRE_DATE', width: 90, sortable: true, unSortIcon: true},
    // {headerName: 'Student Category', field: 'dropOutZone', width: 120, filter: 'agTextColumnFilter'},
    {headerName: 'Email', field: 'EMAIL', width: 210},
    {headerName: 'Mobile', field: 'MOBILE', width: 120},
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120},
    // {headerName: 'Mother Mobile', field: 'motherMobile', width: 110, },
    {headerName: 'No.   of Mentoring', field: 'mentoring_count', width: 110},
  ];

  unRegisteredStdListColumn = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      suppressFilter: true,
      // template: `<a style="font-size: 20px; color: #0066D3; cursor: pointer"></a>`
      template: ` <button style="background: #006DD2; color: white; border: none; padding: 5px 5px;" data-action-type="viewUnregisteredStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></button>`
    },
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 210, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'DUE', field: 'dueAmount', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Proctorial Issue', field: 'proctorial', width: 100, sortable: true, unSortIcon: true},
    {headerName: 'Course Taken', field: 'CourseTaken', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'Expire', field: 'EXPIRE_DATE', width: 90, sortable: true, unSortIcon: true},
    // {headerName: 'Student Category', field: 'dropOutZone', width: 120, filter: 'agTextColumnFilter'},
    {headerName: 'Email', field: 'EMAIL', width: 210},
    {headerName: 'Mobile', field: 'MOBILE', width: 120},
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120},
    // {headerName: 'Mother Mobile', field: 'motherMobile', width: 110, },
    {headerName: 'No.   of Mentoring', field: 'mentoring_count', width: 110},
  ];

  gridApi: GridApi;
  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();

    this.route.params.subscribe((params: Params) => {
      this.headOrAssociateHeadId = params['headOrAssociateHeadId'];
      this.designation = params['designation'];
      this.teacherId = params['teacherId'];
    });

    // console.log(this.headOrAssociateHeadId);
    // console.log(this.designation);
    // console.log(this.teacherId);

    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {  console.error('error');   }
      );
  }
  // getSemesterList() {
  //   const myParams = new HttpParams();
  //   this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
  //     headers: this.appService.getHeadersWithAccessToken(),
  //     params: myParams
  //   })
  //     .subscribe(res => {
  //         // console.log(res);
  //         this.semesterList = res;
  //       },
  //       msg => {
  //         console.error('error');
  //       }
  //     );
  // }

  // getRegisteredStudentList() {
  //   const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
  //   this.httpClient.get(environment.apiUrl + 'teacherAccess/registeredStudent/v1/registeredStudentList', {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
  //     .subscribe(res => {
  //         this.registeredStudentList = res;
  //         this.isRegisteredChecked = false;
  //       },
  //       msg => {
  //         console.error('error');
  //       }
  //     );
  // }
  //
  // getUnRegisteredStudentList() {
  //   const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
  //   this.httpClient.get(environment.apiUrl + 'teacherAccess/registeredStudent/v1/unRegisteredStudentList', {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
  //     .subscribe(res => {
  //         this.unRegisteredStudentList = res;
  //         this.isUnregisteredChecked = false;
  //       },
  //       msg => {
  //         console.error('error');
  //       }
  //     );
  // }


  public getSelectedDataForMentoringStdList(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const rowData = e.event.target.getAttribute('data-action-type');
      switch (rowData) {
        case 'viewMentoringStdList':
          return this.viewSelectedDataForMentoringStdList(data);
      }
    }
  }

  viewSelectedDataForMentoringStdList(data: any) {
    // console.log('row', data);
    this.router.navigate(['/teacher-access/' + data.STUDENT_ID + '/single-student-mentoring']);
  }

  public getSelectedDataForUnregisteredStdList(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const rowData = e.event.target.getAttribute('data-action-type');
      switch (rowData) {
        case 'viewUnregisteredStdList':
          return this.viewSelectedDataForUnregisteredStdList(data);
      }
    }
  }

  viewSelectedDataForUnregisteredStdList(data: any) {
    // console.log('row', data);
    this.router.navigate(['/teacher-access/' + data.STUDENT_ID + '/single-student-mentoring']);
  }

  exportToExcel(): void {
    const params = {
      fileName: 'unRegistered_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.unRegisteredStudentList);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridReady1(params) {
    this.gridApi = params.api;
  }
  exportToCSV() {
    const params = {
      fileName: 'Registered_student_list.csv',
      sheetName: 'Sheet1', // Optional: Specify the sheet name
      exportMode: 'csv',
      // ... other export options
    };
    // this.gridApi.exportDataAsExcel(params);
    this.gridApi.exportDataAsCsv(params);
  }
  // exportToCSVofRegistered() {
  //   const params = {
  //     fileName: 'registeredStudentList.csv',
  //     sheetName: 'Sheet1', // Optional: Specify the sheet name
  //     exportMode: 'csv',
  //     // ... other export options
  //   };
  //   // this.gridApi.exportDataAsExcel(params);
  //   this.gridApi.exportDataAsCsv(params);
  // }



  getRegisteredStudentList() {
    this.isShowData = true;
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/registeredStudent/v1/registeredStudentList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.registeredStudentList = res;
          this.isShowData = false;
        },
        msg => {  console.error('error');    }
      );
  }

  getUnRegisteredStudentList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch).append('teacherId', this.teacherId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/registeredStudent/v1/unRegisteredStudentList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.unRegisteredStudentList = res;
        },
        msg => {  console.error('error');    }
      );
  }


}
