import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppService} from '../../appService';
import printJS from 'print-js';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mentoring-rpt',
  templateUrl: './mentor-assessment-rpt.component.html',
  styleUrls: ['./mentor-assessment-rpt.component.css']
})
export class MentorAssessmentRptComponent implements OnInit {
  mentoringAssessmentRptList: any = [];
  isShowData = false;

  constructor(private appService: AppService, private httpClient: HttpClient, public dialog: MatDialog, public datepipe: DatePipe,
              public dialogRef: MatDialogRef<MentorAssessmentRptComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.getDataDateForReport();
  }

  getDataMonthForReport() {
    this.isShowData = true;
    const myParams = new HttpParams()
      .append('month', this.data.month.toString())
      .append('year', this.data.year.toString())
      .append('departmentId', this.data.departmentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/v2/teacher-monthly-assessment-report?',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.mentoringAssessmentRptList = res;
          this.isShowData = false;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }

  getDataDateForReport() {
    this.isShowData = true;
    const myParams = new HttpParams()
      .append('start_date', this.datepipe.transform(this.data.start_date, 'yyyy-MM-dd').toString())
      .append('end_date', this.datepipe.transform(this.data.end_date, 'yyyy-MM-dd').toString())
      .append('departmentId', this.data.departmentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/v1/teacher-date-to-date-assessment-report?',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.mentoringAssessmentRptList = res;
          this.isShowData = false;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }

  onPrintReport(): void {
    console.log('print');
    printJS({
      printable: 'mentoringAssessmentRpt',
      type: 'html',
      targetStyles: ['*']
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
