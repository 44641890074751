import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppService} from '../../../appService';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Toast, ToastsManager} from 'ng2-toastr';
import {environment} from '../../../../environments/environment';
import {MeetingWithGuardianSaveDialogComponent} from './meeting-with-guardian-save-dialog/meeting-with-guardian-save-dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-meeting-with-guardian',
  templateUrl: './meeting-with-guardian.component.html',
  styleUrls: ['./meeting-with-guardian.component.css']
})
export class MeetingWithGuardianComponent implements OnInit {
  meetingWithGuardianReasonForMeetingOrSemesterFilter: any = '';

  studentId: any = '';
  semesterList: any = [];
  selectedSemesterIdToSearchMeeting = '';
  meetingList: any = [];

  guardian_type = '';
  meeting_topic = '';
  meeting_instruction = '';
  meeting_remarks = '';
  semester_id = '';
  next_meeting_date = '';
  meeting_file_location = '';
  next_meetingTime = '';
  next_meetingHour = '';
  next_meetingMinute = '';
  next_meetingampm = '';

// date filter
  date = new Date();
  minDate = new Date();
  maxDate = new Date(
    this.date.getFullYear(),
    this.date.getMonth() + 2,
    this.date.getDate()
  );

  meetingReasonList: any = [
    {reason: 'Class Monitoring'},
    {reason: 'Registration'},
    {reason: 'Result'},
    {reason: 'Attendance'},
    {reason: 'Payment Due'},
    {reason: 'Extracurricular Activities'},
    {reason: 'Academic Discussion'},
    {reason: 'Personal Dialogue'},
    {reason: 'Career Building'},
    {reason: 'Motivational Talk'}
  ];
  hourList: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  ampmList: any = ['am', 'pm'];
  guardianTypeList: any = ['Father', 'Mother', 'Local Guardian'];

  cdnLocMentoring= environment.cdnLocMentoring;

  @ViewChild('fileInput') fileInput;

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,  public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe( (params: Params) => {  this.studentId = params['studentId'];  }   );
    // console.log(this.studentId);

    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }



  getMeetingList() {
    const myParams = new HttpParams().append('studentId', this.studentId).append('semesterId', this.selectedSemesterIdToSearchMeeting);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/teacherMeetingGuardian/meetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
         // console.log(res);
          this.meetingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }



  saveMeeting() {

    const fileBrowser = this.fileInput.nativeElement;

    const formData = new FormData();
    formData.append('file', fileBrowser.files[0]);
    formData.append('meeting', JSON.stringify({
      guardian_type: this.guardian_type,
      meeting_topic: this.meeting_topic,
      meeting_instruction: this.meeting_instruction,
      meeting_remarks: this.meeting_remarks,
      semester_id: this.semester_id,
      next_meeting_date: this.next_meeting_date ,
      student_id: this.studentId,
      next_meeting_time: this.next_meetingHour + '.' + this.next_meetingMinute + ' ' + this.next_meetingampm
    }) );


    this.httpClient.post(environment.apiUrl + 'teacherAccess/teacherMeetingGuardian/saveMeeting',
      formData, {headers: this.appService.getHeadersWithAccessToken().delete('Content-Type')})
      .subscribe(res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.guardian_type = '';
            this.meeting_topic = '';
            this.meeting_instruction = '';
            this.meeting_remarks = '';
            this.semester_id = '';
            this.next_meeting_date = '';
            this.meeting_file_location = '';
            this.next_meetingHour = '';
            this.next_meetingMinute = '00';
            this.next_meetingampm = '';

            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {     this.toastr.dismissToast(toast);       }, 3000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error(res['message'], 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => {     this.toastr.dismissToast(toast);       }, 5000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 3000);
            });
        }
      );
  }




  openMeetingWithGuardianSaveDialog(): void {

    // console.log('opening dialog');

    const dialogRef = this.dialog.open(MeetingWithGuardianSaveDialogComponent, {
      width: '100%',  data: {today: new Date()}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined ) {
        // console.log("undefined not save");
      } else {
        // console.log("undefined not");
        if (result.confirmStatus === 'yes' ){
          // console.log("save");
          this.saveMeeting();
        }else {
          // console.log("not save");
        }
      }
    });
  }




}
