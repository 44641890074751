import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../../appService';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Toast, ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-mentor-meeting-with-gaurdian',
  templateUrl: './mentor-meeting-with-gaurdian.component.html',
  styleUrls: ['./mentor-meeting-with-gaurdian.component.css']
})
export class MentorMeetingWithGaurdianComponent implements OnInit {
  selectedMentorForSearch: any = '';
  selectedSemesterForSearch: any = '';
  selectedDepartmentForSearch: any = '';
  selectedMentoringTopicForSearch: any = '';
  isShowData: boolean;
  mentorList: any = [];
  semesterList: any = [];
  departmentList: any = [];
  studentData: any = [];
  start_date = '';
  end_date = '';
  MentoringTopics = [
    {reason: 'Academic Discussion'},
    {reason: 'Personal Dialogue'},
    {reason: 'Career Building'},
    {reason: 'Motivational Talk'},
    {reason: 'Class Monitoring'},
    {reason: 'Registration'},
    {reason: 'Result'},
    {reason: 'Attendance'},
    {reason: 'Payment Due'},
    {reason: 'Extracurricular Activities'},
    {reason: 'Conflict'},
    {reason: 'Harassment'},
    {reason: 'Drug'},
    {reason: 'Cyber Bullying'},
    {reason: 'Ragging'},
    {reason: 'Bullying'},
    {reason: 'Extortion'},
    {reason: 'Theft'},
    {reason: 'Fraudulent Behavior'}
  ];
  constructor(private appService: AppService, public datepipe: DatePipe,
              public dialog: MatDialog, private httpClient: HttpClient,
              public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.getSemesterList();
    this.getDepartmentList();
  }
  getMentorList() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.mentorList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }
  getDepartmentList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/v1/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {  console.error('error');   }
      );
  }

  getMentorMeetingWithGuardianReport() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch).
    append('teacher', this.selectedMentorForSearch).append('mentoringTopic', this.selectedMentoringTopicForSearch).
    append('semester', this.selectedSemesterForSearch).
    append('startDate', this.datepipe.transform(this.start_date, 'yyyy-MM-dd').toString()).
    append('endDate', this.datepipe.transform(this.end_date, 'yyyy-MM-dd').toString());
    this.isShowData = true;
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-report/mentor-meeting-with-guardian',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          if (res !== null) {
            this.isShowData = false;
            this.studentData = res;
            this.toastr.success(res['message'], 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {this.toastr.dismissToast(toast); }, 2000); });
          } else {
            this.toastr.warning('Data Not Found', 'Warning!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => {this.toastr.dismissToast(toast); }, 2000); });
          }
        },
        msg => {
          this.isShowData = false;
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }
  dateIsValid() {
    if (this.start_date && this.end_date) {
      return true;
    }else {
      return false;
    }
  }
  isButtonDisable() {
    if (this.selectedDepartmentForSearch && this.selectedMentorForSearch && this.selectedMentoringTopicForSearch && this.dateIsValid()) {
      return false;
    }else if (this.selectedDepartmentForSearch && this.selectedMentorForSearch && this.selectedMentoringTopicForSearch === 0 && this.dateIsValid()) {
      return false;
    } else if (this.selectedDepartmentForSearch && this.selectedMentorForSearch === 0 && this.selectedMentoringTopicForSearch === 0 && this.dateIsValid()) {
      return false;
    }else if (this.selectedDepartmentForSearch && this.selectedMentorForSearch === 0 && this.selectedMentoringTopicForSearch && this.dateIsValid()) {
      return false;
    } else {
      return true;
    }
  }


  // counsellingStudent(pendingMeeting): void {
  //   const dialogRef = this.dialog.open(AscrfctDialogComponent, {
  //     width: 'auto',
  //     disableClose: true,
  //     data: {pendingMeeting: pendingMeeting}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     // console.log('The dialog was closed');
  //     // this.getPendingMeetingList();
  //     this.getCompleteMeetingList();
  //   });
  // }
  exportToExcel(): void {
    const params = {
      fileName: 'topic_wise_mentoring_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }

}
