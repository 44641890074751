import { Component, OnInit } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {AppService} from '../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-registered-student',
  templateUrl: './registered-student.component.html',
  styleUrls: ['./registered-student.component.css']
})
export class RegisteredStudentComponent implements OnInit {
  registeredStudentIdOrNameFilter: any = '';
  unRegisteredStudentIdOrNameFilter: any = '';

  selectedSemesterForSearch: any = '';
  semesterList: any = [];
  registeredStudentList: any = [];
  unRegisteredStudentList: any = [];
  isRegisteredChecked = false;
  isUnregisteredChecked = false;


  registeredStdListColumn = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      suppressFilter: true,
      //template: `<a style="background: #006DD2; color: white; border: none; padding: 5px 5px;" target="_blank" data-action-type="viewMentoringStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></a>`
      template: `<button style="background: #006DD2; color: white; border: none; padding: 5px 5px;" data-action-type="viewMentoringStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></button>`
    },
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 210, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'DUE', field: 'dueAmount', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Proctorial Issue', field: 'proctorial', width: 100, sortable: true, unSortIcon: true},
    {headerName: 'Course Taken', field: 'CourseTaken', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'Expire', field: 'EXPIRE_DATE', width: 90, sortable: true, unSortIcon: true},
    // {headerName: 'Student Category', field: 'dropOutZone', width: 120, filter: 'agTextColumnFilter'},
    {headerName: 'Email', field: 'EMAIL', width: 210},
    {headerName: 'Mobile', field: 'MOBILE', width: 120},
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120},
    // {headerName: 'Mother Mobile', field: 'motherMobile', width: 110, },
    {headerName: 'No.   of Mentoring', field: 'mentoring_count', width: 110},
  ];

  unRegisteredStdListColumn = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      suppressFilter: true,
      // template: `<a style="font-size: 20px; color: #0066D3; cursor: pointer"></a>`
      template: ` <button style="background: #006DD2; color: white; border: none; padding: 5px 5px;" data-action-type="viewUnregisteredStdList">Mentoring&nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i></button>`
    },
    {headerName: 'Student Id', field: 'STUDENT_ID', width: 120, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true},
    {headerName: 'Name', field: 'FIRST_NAME', width: 210, filter: 'agTextColumnFilter'},
    {headerName: 'CGPA', field: 'CGPA', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Credit Completed', field: 'COMPLETED_CREDIT', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'DUE', field: 'dueAmount', width: 80, sortable: true, unSortIcon: true},
    {headerName: 'Proctorial Issue', field: 'proctorial', width: 100, sortable: true, unSortIcon: true},
    {headerName: 'Course Taken', field: 'CourseTaken', width: 120, sortable: true, unSortIcon: true},
    {headerName: 'Expire', field: 'EXPIRE_DATE', width: 90, sortable: true, unSortIcon: true},
    // {headerName: 'Student Category', field: 'dropOutZone', width: 120, filter: 'agTextColumnFilter'},
    {headerName: 'Email', field: 'EMAIL', width: 210},
    {headerName: 'Mobile', field: 'MOBILE', width: 120},
    {headerName: 'Father Mobile', field: 'fatherMobile', width: 120},
    // {headerName: 'Mother Mobile', field: 'motherMobile', width: 110, },
    {headerName: 'No.   of Mentoring', field: 'mentoring_count', width: 110},
  ];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

  getRegisteredStudentList() {
      const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
      this.httpClient.get(environment.apiUrl + 'teacherAccess/registeredStudent/v1/registeredStudentList', {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
        .subscribe(res => {
            this.registeredStudentList = res;
            this.isRegisteredChecked = false;
          },
          msg => {
            console.error('error');
          }
        );
  }

  getUnRegisteredStudentList() {
      const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch);
      this.httpClient.get(environment.apiUrl + 'teacherAccess/registeredStudent/v1/unRegisteredStudentList', {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
        .subscribe(res => {
            this.unRegisteredStudentList = res;
            this.isUnregisteredChecked = false;
          },
          msg => {
            console.error('error');
          }
        );
  }


  public getSelectedDataForMentoringStdList(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const rowData = e.event.target.getAttribute('data-action-type');
      switch (rowData) {
        case 'viewMentoringStdList':
          return this.viewSelectedDataForMentoringStdList(data);
      }
    }
  }

  viewSelectedDataForMentoringStdList(data: any) {
    //console.log('row', data);
    this.router.navigate(['/teacher-access/' + data.STUDENT_ID + '/single-student-mentoring']);
  }

  public getSelectedDataForUnregisteredStdList(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const rowData = e.event.target.getAttribute('data-action-type');
      switch (rowData) {
        case 'viewUnregisteredStdList':
          return this.viewSelectedDataForUnregisteredStdList(data);
      }
    }
  }

  viewSelectedDataForUnregisteredStdList(data: any) {
    //console.log('row', data);
    this.router.navigate(['/teacher-access/' + data.STUDENT_ID + '/single-student-mentoring']);
  }

  exportToExcel(): void {
    const params = {
      fileName: 'mentoring_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.registeredStudentList);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }
}
