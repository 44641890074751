import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';

import 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Params, Router} from '@angular/router';

import {Toast, ToastsManager} from 'ng2-toastr/ng2-toastr';

import { environment } from '../../environments/environment';
import {AppService} from '../appService';

@Component({
  selector: 'app-assign-dean-monitor-associate-head',
  templateUrl: './assign-dean-monitor-associate-head.component.html',
  styleUrls: ['./assign-dean-monitor-associate-head.component.css']
})
export class AssignDeanMonitorAssociateHeadComponent implements OnInit {
  deanIdOrNameFilter: any = '';
  teacherIdOrNameFilter: any = '';
  alreadyAssignedHeadIdOrNameFilter: any = '';

  employeeIdorNameToSearchDean = '';
  employeeIdorNameToSearchAssociateHead = '';
  deanList: any = [];
  associateHeadList: any = [];
  selectedDean: any= {};
  selectedAssociateHeadList = [];

  alreadyAssignedDeanAssociateHeadList: any = [];
  deanIdAndNameToSearchAlreadyAssignedDeanAssociateHeadList= '';
  associateHeadIdAndNameToSearchAlreadyAssignedDeanAssociateHeadList = '';

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {    this.appService.hasAccessToken();   }

  getDeanListForAssociateHead() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchDean);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorAssociateHead/employeeList',
      {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.deanList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getAssociateHeadList() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchAssociateHead);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorAssociateHead/employeeList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.associateHeadList = res;
        },
        msg => {         console.error('error');    }
      );
  }

  selectDean(d) {
    this.selectedDean = d;
  }

  selectAssociateHead(ah) {
    this.selectedAssociateHeadList.push(ah);
  }

  clearAssociateHeadList() {
    this.selectedAssociateHeadList = [];

  }
  clearSelectedDean() {
    this.selectedDean = {};
  }
  clearSingleSelectedAssociateHead(selected, index) {
    this.selectedAssociateHeadList.splice(index, 1);
  }



  assignDeanToMonitorAssociateHead() {
    const tempArr = [];
    for (const sah of this.selectedAssociateHeadList)  {  tempArr.push(sah.EMPLOYEE_ID);   }
    this.httpClient.post(environment.apiUrl + 'assignDeanMonitorAssociateHead/assignDean',
      {deanId: this.selectedDean.EMPLOYEE_ID, associateHeadIdList: tempArr},
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(  res => {
          // console.log(res);

          if (res['status'] === 'success') {
            this.toastr.success('Successfully Assigned!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {this.selectedDean = {}, this.selectedAssociateHeadList = []; setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error('Assigned Failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }


  alreadyAssignedDeanAssociateHeadListSearchByDean() {
    const myParams = new HttpParams().append('deanIdorName', this.deanIdAndNameToSearchAlreadyAssignedDeanAssociateHeadList);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorAssociateHead/deanAssociateHeadListByDean',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedDeanAssociateHeadList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  alreadyAssignedDeanAssociateHeadListSearchByAssociateHead() {
    const myParams = new HttpParams().append('associateHeadIdorName', this.associateHeadIdAndNameToSearchAlreadyAssignedDeanAssociateHeadList);
    this.httpClient.get(environment.apiUrl + 'assignDeanMonitorAssociateHead/deanAssociateHeadListByAssociateHead',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedDeanAssociateHeadList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  deleteAssignedAssociateHeadFromTeacher(id) {
    console.log(id);
    const myParams = new HttpParams().append('id', id);
    this.httpClient.delete(environment.apiUrl + 'assignDeanMonitorAssociateHead/deleteAssignedDeanToAssociateHead',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success('Successfully Deleted!', 'Success!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => { this.alreadyAssignedDeanAssociateHeadListSearchByDean(); setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);   });
          } else {
            this.toastr.success('Delete Failed!', 'Failed!', {dismiss: 'click', showCloseButton: true, animate: 'flyLef'})
              .then((toast: Toast) => {    setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);   });
          }
        },
        msg => {    console.error('error');    }
      );
  }


}
