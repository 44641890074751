import { Component, OnInit } from '@angular/core';
import {MatDatepickerInputEvent, MatSnackBar, MatTableDataSource} from '@angular/material';
import {AppService} from '../appService';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {MentorAssessmentRptComponent} from './mentor-assessment-rpt/mentor-assessment-rpt.component';
import { DatePipe } from '@angular/common';
import {GridApi} from 'ag-grid-community';



@Component({
  selector: 'app-teacher-lookup',
  templateUrl: './mentor-assessment.component.html',
  styleUrls: ['./mentor-assessment.component.css']
})
export class MentorAssessmentComponent implements OnInit {
  teacherId: any;
  month: number = null;
  year: number;
  department: string = null;
  semester: string = null;
  isShowData: boolean;
  semesterList: any = [];
  isShowPrintRpt: boolean;
  departmentList: any = [];

  mentoringList: any = {};
  teacherAssessmentInfo: any;
  semesterData: any = null;

  mentoringRpt: any = null;
  start_date = '';
  end_date = '';

  monthList: any = [
    {id: 1, name: 'January'}, {id: 2, name: 'February'}, {id: 3, name: 'March'}, {id: 4, name: 'April'},
    {id: 5, name: 'May'}, {id: 6, name: 'June'}, {id: 7, name: 'July'}, {id: 8, name: 'August'},
    {id: 9, name: 'September'}, {id: 10, name: 'October'}, {id: 11, name: 'November'}, {id: 12, name: 'December'},
  ];
  mentorAssessmentListColumn = [
    {headerName: 'SL', valueGetter: 'node.rowIndex+1', cellClass: 'locked-col', width: 80, suppressNavigable: true},
    {headerName: 'Teacher ID', field: 'teacherId', filter: 'agTextColumnFilter'},
    {headerName: 'Teacher Name', field: 'teacherName', filter: 'agTextColumnFilter', resizable: true},
    {headerName: 'Designation', field: 'designation'},
    // {headerName: 'Department', field: 'department',  filter: 'agTextColumnFilter'},
    {headerName: 'Student Counselling', field: 'totalMeetingsWithStudentsPoint', resizable: true},
    {headerName: 'Mentor Counselling Request', field: 'totalCounsellingRequestSendPoint', resizable: true},
    {headerName: 'Mentor Meeting With Students', field: 'totalMeetingsWithStudentsAsMentorPoint', resizable: true},
    {headerName: 'Mentor Meeting With Guardian', field: 'totalMeetingsWithGuardiansPoint', resizable: true},
    {headerName: 'Meeting with Course Teacher', field: 'totalMeetingWithCourseTeacherScore', resizable: true},
    // {headerName: 'Total Meetings With Guardians Point', field: 'totalMeetingsWithGuardiansPoint', resizable: true},
    {
      headerName: 'Total Point',
      valueGetter: function (params) {
        const sumPoint = params.data.totalMeetingsWithStudentsPoint
            + params.data.totalCounsellingRequestSendPoint
            + params.data.totalMeetingsWithStudentsAsMentorPoint
            + params.data.totalMeetingsWithGuardiansPoint
            + params.data.totalMeetingWithCourseTeacherScore;
        return sumPoint.toFixed(2);
      }
    },
  ];
  gridApi: GridApi;
  constructor(private appService: AppService, private _sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, public datepipe: DatePipe,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, public dialog: MatDialog) { }

  ngOnInit() {
    this.getDepartmentList();
    this.getSemesterList();
  }

  getDepartmentList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/v1/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'studentCounselling/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }
  getMentorAssessment() {
    this.isShowData = true;
    const myParams = new HttpParams()
      .append('month', this.month.toString())
      .append('year', this.year.toString())
      .append('departmentId', this.department);
    this.httpClient.get(environment.apiUrl + 'panelAccess/v2/teacher-monthly-assessment-report?',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          console.log(res);
          this.isShowData = false;
          this.teacherAssessmentInfo = res;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  getMentorAssessmentDate() {
    this.isShowData = true;
    // console.log('Start Date:');
    // console.log(this.start_date);
    const myParams = new HttpParams()
      .append('start_date', this.datepipe.transform(this.start_date, 'yyyy-MM-dd').toString())
      .append('end_date', this.datepipe.transform(this.end_date, 'yyyy-MM-dd').toString())
      .append('departmentId', this.department.toString())
      .append('semester', this.semester);
    this.httpClient.get(environment.apiUrl + 'panelAccess/v1/teacher-date-to-date-assessment-report?',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(res => {
          // console.log(res);
          this.isShowData = false;
          this.teacherAssessmentInfo = res;
        },
        msg => {
          console.error('error');
          this.isShowData = false;
        }
      );
  }

  openMentoringAssessmentRptDialog(): void {
    const searchObj = {start_date: this.start_date, end_date: this.end_date, departmentId: this.department};
    const dialogRef = this.dialog.open(MentorAssessmentRptComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: searchObj
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  exportToExcel() {
    const params = {
      fileName: 'mentor_assessment_data.csv',
      sheetName: 'Sheet1', // Optional: Specify the sheet name
      exportMode: 'csv',
      // ... other export options
    };
    // this.gridApi.exportDataAsExcel(params);
    this.gridApi.exportDataAsCsv(params);
  }
  // exportToExcel1(): void {
  //   const params = {
  //     fileName: 'mentoring_assessment_list.xlsx',
  //     sheetName: 'Sheet1',
  //   };
  //   // Convert data to worksheet
  //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.teacherAssessmentInfo);
  //
  //   // Create a workbook
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, params.sheetName);
  //
  //   // Save to file
  //   XLSX.writeFile(wb, params.fileName);
  // }

}
