import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../../appService';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-student-attendance-and-cgpa',
  templateUrl: './student-attendance-and-cgpa.component.html',
  styleUrls: ['./student-attendance-and-cgpa.component.css']
})
export class StudentAttendanceAndCgpaComponent implements OnInit {
  selectedFacultyForSearch: any = '';
  selectedProgramForSearch: any = '';
  selectedDepartmentForSearch: any = '';
  selectedCgpaRangeForSearch: any = '';
  selectedCgpaRange: any = '';
  isShowData: boolean;
  facultyList: any = [];
  programList: any = [];
  departmentList: any = [];
  studentData: any = [];
  cgpaRange = [
    { id: { min: 0.0, max: 1.0 }, value: 'CGPA 0.0-1.0' },
    { id: { min: 1.0, max: 2.0 }, value: 'CGPA 1.0-2.0'},
    { id: { min: 2.0, max: 3.0 }, value: 'CGPA 2.0-3.0'},
    { id: { min: 3.0, max: 3.5 }, value: 'CGPA 3.0-3.5'},
    { id: { min: 3.5, max: 4.0 }, value: 'CGPA 3.5-4.0'}
  ];
  constructor(private appService: AppService,
              public dialog: MatDialog, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) { }

  ngOnInit() {
    this.getFacultyList();
    // this.getProgramList();
    // this.getDepartmentList();
  }

  getFacultyList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/facultyList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.facultyList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getDepartmentList() {
    const myParams = new HttpParams().append('faculty', this.selectedFacultyForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/department-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.departmentList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }
  getProgramList() {
    const myParams = new HttpParams().append('department', this.selectedDepartmentForSearch);
    this.httpClient.get(environment.apiUrl + 'panelAccess/program-list', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.programList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  isButtonDisable() {
    if (this.selectedDepartmentForSearch && this.selectedFacultyForSearch && this.selectedCgpaRangeForSearch && this.selectedProgramForSearch) {
      return false;
    }else if (this.selectedDepartmentForSearch && this.selectedFacultyForSearch && this.selectedProgramForSearch === '' && this.selectedCgpaRangeForSearch) {
      return false;
    }  else {
      return true;
    }
  }
  getCGPAWiseStudentList() {
    console.log(this.selectedCgpaRangeForSearch);
    this.selectedCgpaRange = JSON.stringify(this.selectedCgpaRangeForSearch);
    this.selectedProgramForSearch = this.selectedProgramForSearch === '' ? 0 : this.selectedProgramForSearch;
    const myParams = new HttpParams().append('faculty', this.selectedFacultyForSearch).
    append('department', this.selectedDepartmentForSearch).append('program', this.selectedProgramForSearch)
      .append('cgpaRange', this.selectedCgpaRange);
    this.isShowData = true;
    this.httpClient.get(environment.apiUrl + 'panelAccess/mentor-report/student-cgpa-report',
      { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          // console.log('pending res' , res);
          this.isShowData = false;
          this.studentData = res;
          this.selectedProgramForSearch = this.selectedProgramForSearch === 0 ? '' : this.selectedProgramForSearch;
        },
        msg => {
          this.isShowData = false;
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  exportToExcel(): void {
    const params = {
      fileName: 'cgpa_wise_student_list.xlsx',
      sheetName: 'Sheet1',
    };

    // Convert data to worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.studentData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

    // Save to file
    XLSX.writeFile(wb, params.fileName);
  }

}
