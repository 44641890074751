import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';

import 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Params, Router} from '@angular/router';

import {Toast, ToastsManager} from 'ng2-toastr/ng2-toastr';
import { environment } from '../../environments/environment';
import {AppService} from '../appService';


@Component({
  selector: 'app-assign-associate-head-monitor-teacher',
  templateUrl: './assign-associate-head-monitor-teacher.component.html',
  styleUrls: ['./assign-associate-head-monitor-teacher.component.css']
})
export class AssignAssociateHeadMonitorTeacherComponent implements OnInit {
  associateHeadIdOrNameFilter: any = '';
  teacherIdOrNameFilter: any = '';
  alreadyAssignedTeacherIdOrNameFilter: any = '';

  employeeIdorNameToSearchAssociateHead = '';
  employeeIdorNameToSearchTeacher = '';
  associateHeadList: any = [];
  teacherList: any = [];
  selectedAssociateHead: any = {};
  selectedTeacherList: any = [];


  associatedHeadIdAndNameToSearchAlreadyAssignedTeacherList= '';
  teacherIdAndNameToSearchAlreadyAssignedTeacherList= '';
  alreadyAssignedTeacherList: any= [];

  constructor(private appService: AppService,        private localStorage: LocalStorageService , private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
  }

  getTeacherListForAssociateHead() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchAssociateHead);
    this.httpClient.get(environment.apiUrl + 'assignAssociateHeadMonitorTeacher/employeeList',  {headers:  this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.associateHeadList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getTeacherListForTeacher() {
    const myParams = new HttpParams().append('employeeIdorName', this.employeeIdorNameToSearchTeacher);
    this.httpClient.get(environment.apiUrl + 'assignAssociateHeadMonitorTeacher/employeeList',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.teacherList = res;
        },
        msg => {         console.error('error');    }
      );
  }

  selectAssociateHead(ah) {
    this.selectedAssociateHead = ah;
    // console.log(this.selectedAssociateHead);
  }

  selectTeacher(t) {
    this.selectedTeacherList.push(t);
    /*for (const te of this.selectedTeacherList) {   // console.log(te);   }*/
  }

  clearAssociateHeadAndTeacher() {
    this.selectedAssociateHead = {};
    this.selectedTeacherList = [];

  }
  clearSelectedAssociateHead() {
    this.selectedAssociateHead = {};
  }
  clearSingleSelectedTeacher(selected, index) {
    this.selectedTeacherList.splice(index, 1);
    /*for (let i int = 0; i > this.selectedTeacherList.length; i++) {
      if (this.selectedTeacherList[i].EMPLOYEE_ID === selected.EMPLOYEE_ID) {
        this.selectedTeacherList.splice(i, 1);
      }
    }*/
  }

  alreadyAssignedTeacherListSearchByAssociatedHead() {
    const myParams = new HttpParams().append('associateHeadIdorName', this.associatedHeadIdAndNameToSearchAlreadyAssignedTeacherList);
    this.httpClient.get(environment.apiUrl + 'assignAssociateHeadMonitorTeacher/teacherListByAssociateHead',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedTeacherList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  assignAssociateHeadToMonitorTeacher() {
    const tempArr = [];
    for (const te of this.selectedTeacherList)  {  tempArr.push(te.EMPLOYEE_ID);   }
    this.httpClient.post(environment.apiUrl + 'assignAssociateHeadMonitorTeacher/assignAssociateHead',
      {associateHeadEmployeeId: this.selectedAssociateHead.EMPLOYEE_ID, teacherEmployeeIdList: tempArr},
      {headers: this.appService.getHeadersWithAccessToken()})
      .subscribe(  res => {
          // console.log(res);
          if (res['status'] === 'success') {
            this.toastr.success('Successfully Assigned!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {this.selectedAssociateHead = {}, this.selectedTeacherList = []; setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error('Assigned Failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => { this.toastr.dismissToast(toast); }, 2000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }

  alreadyAssignedTeacherListSearchByTeacher() {
    const myParams = new HttpParams().append('teacherIdorName', this.teacherIdAndNameToSearchAlreadyAssignedTeacherList);
    this.httpClient.get(environment.apiUrl + 'assignAssociateHeadMonitorTeacher/teacherListByTeacher',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          this.alreadyAssignedTeacherList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  deleteAssignedAssociateHeadFromTeacher(associateheadTeacherId) {
    const myParams = new HttpParams().append('associateheadTeacherId', associateheadTeacherId);
    this.httpClient.delete(environment.apiUrl + 'assignAssociateHeadMonitorTeacher/deleteAssignedAssociateHeadToTeacher',
      {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
                     // console.log(res);

          if (res['status'] === 'success') {
            this.toastr.success('Successfully Deleted!', 'Success!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {this.alreadyAssignedTeacherListSearchByAssociatedHead(); setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);         });
          }
          if (res['status'] === 'failed') {
            this.toastr.error('Delete Failed!', 'Fail!', {dismiss: 'click', showCloseButton: true,       animate: 'flyLef'})
              .then((toast: Toast) => {  setTimeout(() => {     this.toastr.dismissToast(toast);       }, 2000);  });
          }
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
          this.toastr.error('Request Failed!', 'Oops!', {dismiss: 'click', showCloseButton: false, animate: 'flyLef'})
            .then((toast: Toast) => { setTimeout(() => {this.toastr.dismissToast(toast); }, 2000);
            });
        }
      );
  }


}
