import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ToastsManager} from 'ng2-toastr';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppService} from '../../../../appService';
import {MatDialog} from '@angular/material';
import {environment} from '../../../../../environments/environment';
import {CrfctDialogComponent} from '../../../../teacher-access/single-student-mentoring/meeting-with-course-teacher/counselling-request-from-course-teacher/crfct-dialog/crfct-dialog.component';

@Component({
  selector: 'app-request-from-course-teacher-by-panel',
  templateUrl: './request-from-course-teacher-by-panel.component.html',
  styleUrls: ['./request-from-course-teacher-by-panel.component.css']
})
export class RequestFromCourseTeacherByPanelComponent implements OnInit {


  selectedSemesterForSearch: any = '';
  pendingMeetingListFilter: any = '';
  completeMeetingListFilter: any = '';

  semesterList: any = [];
  pendingMeetingList: any = [];
  completedMeetingList: any = [];


  headOrAssociateHeadId: any = '';
  designation: any = '';
  teacherId: any = '';
  studentId: any = '';


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr); }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe((params: Params) => {
      this.headOrAssociateHeadId = params['headOrAssociateHeadId'];
      this.designation = params['designation'];
      this.studentId = params['studentId'];
      this.teacherId = params['teacherId'];
    });
    this.getSemesterList();
  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'panelAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getPendingMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/requestFromCourseTeacher/pendingMeetingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          console.log('pending res' , res);
          this.pendingMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  getCompleteMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'panelAccess/requestFromCourseTeacher/completeMeetingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
          console.log('res' , res);
          this.completedMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }



}
