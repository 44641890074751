import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppService} from '../../../../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {Toast, ToastsManager} from 'ng2-toastr';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {CrfctDialogComponent} from './crfct-dialog/crfct-dialog.component';
import {CrfctInstructionDialogComponent} from './crfct-instruction-dialog/crfct-instruction-dialog.component';

@Component({
  selector: 'app-counselling-request-from-course-teacher',
  templateUrl: './counselling-request-from-course-teacher.component.html',
  styleUrls: ['./counselling-request-from-course-teacher.component.css']
})
export class CounsellingRequestFromCourseTeacherComponent implements OnInit {

  selectedSemesterForSearch: any = '';
  studentId: any = '';
  pendingMeetingListFilter: any = '';
  completeMeetingListFilter: any = '';

  semesterList: any = [];
  pendingMeetingList: any = [];
  completedMeetingList: any = [];




  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr); }


  ngOnInit() {
    this.appService.hasAccessToken();
    this.route.params.subscribe( (params: Params) => { this.studentId = params['studentId'];  }   );
    this.getSemesterList();
  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // // console.log(res);
        this.semesterList = res;
      },
      msg => {
        console.error('error');
      }
    );
  }

  getPendingMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/requestFromCourseTeacher/pendingMeetingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
           // console.log('pending res' , res);
          this.pendingMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }

  getCompleteMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch).append('studentId', this.studentId);
    this.httpClient.get(environment.apiUrl + 'teacherAccess/requestFromCourseTeacher/completeMeetingList', { headers: this.appService.getHeadersWithAccessToken(), params: myParams })
      .subscribe(
        res => {
           // console.log('res' , res);
          this.completedMeetingList = res;
        },
        msg => {
          console.error(`Error: ${msg.status} ${msg.statusText}`);
        }
      );
  }


  counsellingStudent(pendingMeeting): void {
    const dialogRef = this.dialog.open(CrfctDialogComponent, {
      width: 'auto',
      disableClose: true,
      data: {pendingMeeting: pendingMeeting}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.getPendingMeetingList();
      this.getCompleteMeetingList();
    });
  }


  openCrfctInstructionDialog(): void {
    const dialogRef = this.dialog.open(CrfctInstructionDialogComponent, {
      width: '60%',
      height: 'auto',
      disableClose: false,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }




}
