import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {AppService} from '../appService';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-associate-head-access',
  templateUrl: './associate-head-access.component.html',
  styleUrls: ['./associate-head-access.component.css']
})
export class AssociateHeadAccessComponent implements OnInit {
  teacherIdOrNameFilter: any = '';
  mentoringStudentIdOrNameFilter: any = '';
  passoutOrCompletedStudentIdOrNameFilter: any = '';


  employeeList: any = [] ;
  studentList: any = [] ;
  selectedTeacherId: any = '';
  selectedTeacherName: any = '';
  selectedStudent: any = {} ;

  studentListForMentoring: any = [];
  studentListForComplete: any = [];



  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {

  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.getEmployeeListForHead();
  }

  getEmployeeListForHead() {
    this.httpClient.get(environment.apiUrl + 'associateHeadAccess/employeeList',  {headers: this.appService.getHeadersWithAccessToken() })
      .subscribe(  res => {
          // console.log(res);
          this.employeeList = res;
        },
        msg => {    console.error('error');    }
      );
  }

  getStudentListForTeacher(teacher_id, teacher_first_name) {

    this.selectedTeacherId = teacher_id;
    this.selectedTeacherName = teacher_first_name;

    this.getStudentListForMentoring(teacher_id);
    this.getStudentListForComplete(teacher_id);

    /* const myParams = new HttpParams().append('teacherId', teacher_id);
     this.httpClient.get(environment.apiUrl + 'associateHeadAccess/studentListByTeacher',
        {headers: this.appService.getHeadersWithAccessToken() , params: myParams})
        .subscribe(  res => {       this.studentList = res;   },
          msg => {    console.error('error');    }
        );*/
  }


  getStudentListForMentoring(teacher_id) {
    const myParams = new HttpParams().append('teacherId', teacher_id);
    this.httpClient.get(environment.apiUrl + 'associateHeadAccess/studentListForMentoring',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForMentoring = res;
        },
        msg => {  console.error('error');    }
      );
  }

  getStudentListForComplete(teacher_id) {
    const myParams = new HttpParams().append('teacherId', teacher_id);
    this.httpClient.get(environment.apiUrl + 'associateHeadAccess/studentListForComplete',  {headers: this.appService.getHeadersWithAccessToken(), params: myParams})
      .subscribe(  res => {
          // console.log(res);
          this.studentListForComplete = res;
        },
        msg => {         console.error('error');    }
      );
  }

  goToSingleStudentMentoringLink(studentId) {
    this.router.navigate(['associate-head-access/' + studentId + '/' + this.selectedTeacherId + '/single-student-mentoring']);
  }


}
