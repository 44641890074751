import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppService} from '../../appService';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-all-student-next-meeting',
  templateUrl: './all-student-next-meeting.component.html',
  styleUrls: ['./all-student-next-meeting.component.css']
})
export class AllStudentNextMeetingComponent implements OnInit {

  reasonForMeetingOrSemesterFilter: any = '';

  selectedSemesterIdToSearchMeeting = '';
  semesterList: any = [];
  meetingList: any = [];


  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private route: ActivatedRoute,
              private router: Router, private httpClient: HttpClient,   public toastr: ToastsManager, vcr: ViewContainerRef) {
    this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    this.appService.hasAccessToken();
    this.getSemesterList();
  }

  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'teacherAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.semesterList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }


  getMeetingList() {
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterIdToSearchMeeting);

    this.httpClient.get(environment.apiUrl + 'teacherAccess/allStudentNextMeeting/meetingList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.meetingList = res;
        },
        msg => {
          console.error('error');
        }
      );
  }

}
