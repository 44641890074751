import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {AppService} from '../../appService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsManager} from 'ng2-toastr';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-advising-target-setting-and-kpi-by-associate-head',
  templateUrl: './advising-target-setting-and-kpi-by-associate-head.component.html',
  styleUrls: ['./advising-target-setting-and-kpi-by-associate-head.component.css']
})
export class AdvisingTargetSettingAndKpiByAssociateHeadComponent implements OnInit {

  isSearchResultShow = false;

  browserDetectionMessage = '';
  associateHeadDetails: any = {};
  selectedSemesterForSearch: any = null;
  semesterList: any = [];
  advisingTargetList: any = [];

  constructor(private appService: AppService,
              private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService, public dialog: MatDialog,
              private router: Router, private httpClient: HttpClient, public toastr: ToastsManager) {
  }

  ngOnInit() {

    console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");

    this.appService.hasAccessToken();
    this.getSemesterList();
    this.getAssociateHeadDetails();

    const isChrome = !!window['chrome'] && !!window['chrome']['webstore'];
    // console.log(isChrome);
    if (!isChrome) {
      this.browserDetectionMessage = ' Note: You are not using Google Chrome browser.Use Google Chrome To print the document.';
    }



  }


  getSemesterList() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/associateHeadAccess/semesterList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.semesterList = res;
      }, msg => {
        console.error('error');
      }
    );
  }

  getAssociateHeadDetails() {
    const myParams = new HttpParams();
    this.httpClient.get(environment.apiUrl + 'report/associateHeadAccess/associateHeadDetails', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    }).subscribe(res => {
        // console.log(res);
        this.associateHeadDetails = res;
      }, msg => {
        console.error('error');
      }
    );
  }

  getAdvisingTargetList() {
    this.isSearchResultShow = true;
    const myParams = new HttpParams().append('semesterId', this.selectedSemesterForSearch.SEMESTER_ID);
    this.httpClient.get(environment.apiUrl + 'report/associateHeadAccess/advisingTargetList', {
      headers: this.appService.getHeadersWithAccessToken(),
      params: myParams
    })
      .subscribe(res => {
          // console.log(res);
          this.advisingTargetList = res;
          this.isSearchResultShow = false;
        },
        msg => {
          console.error('error');
        }
      );
  }

}
